import React, {useEffect, useState} from 'react';
import styled from "styled-components";
const Tab = {
    Wrap: styled.ul`
      display:inline-flex;overflow:hidden;border-radius:50px;border:1px solid #3100ff;
      @media screen and (max-width:1280px){
        width:480px;
      }
      @media screen and (max-width:1024px){
        width:450px;
      }
      @media screen and (max-width:1024px){
        width:300px;
      }
    `,
    Item: styled.li`
      height:100%;flex:1;color:#3100ff;font-size:20px;font-weight:400;background:#fff;border-radius:50px;line-height:52px;width:208px;cursor:pointer;
      @media screen and (max-width:1280px){
        height:46px;line-height:46px;font-size:18px;
      }
      @media screen and (max-width:1024px){
        height:42px;line-height:42px;
      }
      @media screen and (max-width:768px){
        height:40px;line-height:40px;font-size:16px;
      }
      &.active{background:#3100ff;color:#fff;cursor:default;}
    `
}
const Tab2 = ({list,onClick}) => {

    return (
        <Tab.Wrap>
            {
                list.map(({name,active},index) => {
                    return <Tab.Item key={index} className={active ? 'active' : undefined} onClick={() => onClick(name)}>{name}</Tab.Item>
                })
            }
        </Tab.Wrap>
    );
};

export default Tab2;