import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import i18n from "i18next";

const Combo = {
    Wrap:styled.div`
      position:relative; width:100px;display:flex;align-items:center;justify-content:center;
      @media screen and (max-width:1024px){
        position:absolute;right:80px;width:auto;
      }
    `,
    Selected:styled.strong`
      position:relative;display:flex;align-items:center;font-size:20px;font-weight:600;color:#fff;cursor: pointer;height:110px;
      &:after{position:absolute;left:calc(100% + 20px);top:calc(50% - 7px);transform:rotate(-135deg);
        display:block;content:'';border:3px solid transparent; border-left:3px solid #fff;border-top:3px solid #fff;}
      @media screen and (max-width:1024px){
        margin-top:22px;font-size:14px;width:100%;height:100%;justify-content:center;padding:0 30px;
        &:after{left:calc(100% - 10px);}
      }
      &.s2, &.s3, &.s4{color:#1b1b1b;
        &:after{border-left:3px solid #1b1b1b;border-top:3px solid #1b1b1b;}
      }
    `,
    List:styled.ul`
      position:absolute;left:0;top:100%;display:none;padding:28px 0; width:100px;text-align:center;
      background:rgba(0,0,0,.7);box-shadow: 0 9px 8px 0 rgba(0, 0, 0, 0.28);
      &.active{display:block;}
      @media screen and (max-width:1024px){
        left:0;top:calc(100% + 22px);width:auto;padding:20px 30px;
      }
    `,
    Item:styled.li`
      color:#c3c3c3;font-size:20px;font-weight:400;cursor: pointer;
      &:not(:last-child){margin-bottom:28px;}
      &:hover{
        color:#fff;font-weight:600;
      }
      @media screen and (max-width:1024px){
        font-size:14px;
      }
    `
}

const LanguageCombo = ({className}) => {
    const [show, setShow] = useState(false)
    const [langList, setLangList] = useState([
        {name:'KOR',lang:'ko',active:localStorage.getItem('MOVE_LANG') === 'ko'},
        {name:'ENG',lang:'en',active:localStorage.getItem('MOVE_LANG') === 'en'}
    ])
    const onClick = () => {
        setShow(!show);
    }
    const onChangeLang = (language) => {
        let newArr = [...langList];
        newArr.map((i) => {
            i.lang === language ? i.active = true : i.active = false
        })
        setLangList(newArr)
        localStorage.setItem('MOVE_LANG', language)
        i18n.changeLanguage(language)
        onClick();

    }
    const comboRef = useRef();
    //외부영역 클릭시 셀렉트박스 닫기
    const handleClickOutSide = (e) => {
        if (show && !comboRef.current.contains(e.target)) {
            setShow(false)
        }
    }
    useEffect(() => {
        if(show) window.addEventListener('mousedown', handleClickOutSide )
        return () => window.removeEventListener('mousedown', handleClickOutSide)
    })

    return (
        <Combo.Wrap ref={comboRef}>
            <Combo.Selected className={className ? className : undefined} onClick={onClick}>{!localStorage.getItem('MOVE_LANG') ? 'KOR' : langList.filter(({active}) => active)[0].name}</Combo.Selected>
            <Combo.List className={show ? 'active' : undefined}>
                {
                    langList.map(({name, lang, active}) => {
                        return <Combo.Item key={lang} className={active ? 'active' : undefined} onClick={() => onChangeLang(lang)}>{name}</Combo.Item>
                    })
                }

            </Combo.List>
        </Combo.Wrap>
    );
};

export default LanguageCombo;