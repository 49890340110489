import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import TileLayer from "ol/layer/Tile";
import {Feature, Map} from 'ol';
import {OSM, XYZ} from "ol/source";
import {View} from "ol";
import {Point} from "ol/geom";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {Circle, Fill, Stroke, Style, Text} from "ol/style";
const MapStyle = {
    Wrap:styled.div`
      margin-top:68px;
      @media screen and (max-width:1024px){
        margin-top:60px;
      }
    `,
    Address:styled.p`
      display:block;margin-bottom:52px;color:#000;font-size:20px;font-weight:400;text-align:center;word-break:keep-all;
      @media screen and (max-width:1024px){
        font-size:18px;margin-bottom:40px;
      }
      @media screen and (max-width:768px){
        font-size:16px;margin-bottom:28px;line-height:24px;
      }
      
    `,
    Map:styled.div`
      height:653px;
      @media screen and (max-width:1024px){
        height: 400px;
      }
      @media screen and (max-width:1024px){
        height: 300px;
      }
    `
}

const OlMap = ({tag,address,lat,lng}) => {
    const [mapInstance, setMapInstance] = useState()
    const [isReady, setIsReady] = useState(false)
    useEffect(() => {
        if (!document.querySelector(".ol-viewport")) {
            const mapInstance = new Map({
                target: 'mapContainer',
                layers: [
                    new TileLayer({
                        source: new XYZ({
                            url: 'http://mt0.google.com/vt/lyrs=m&hl=ko&x={x}&y={y}&z={z}'
                        }),
                        zIndex: 1 //타일레이어 ZIndex 지정
                    }),
                ],
                view: new View({
                    zoom: 11.5, //초기 줌레벨 지정
                    // maxZoom: 20, //줌 확대 제한
                    // minZoom:11,
                    center: [lng, lat],
                    projection: 'EPSG:4326',
                }),
            });
            setMapInstance(mapInstance)
            // mapInstance.getView()
            setIsReady(true)
        }
    },[])
    useEffect(() => {
        if(isReady){
            mapInstance.getAllLayers().forEach((i) => {
                i.get('id') === 'marker' && mapInstance.removeLayer(i)
            })
            mapInstance.getView().setCenter([lng,lat])
            const feature = new Feature({
                geometry:new Point([lng,lat])
            })
            const source = new VectorSource({
                features:[feature]
            })
            const layer = new VectorLayer({
                source:source,
                style:new Style({
                    image:new Circle({
                        radius:10,
                        fill:new Fill({
                            color:'#ff3c54'
                        }),
                        stroke:new Stroke({
                            width:2,
                            color:'#fff'
                        })
                    }),
                    text:new Text({
                        text:tag,
                        font:'500 16px NotoSans',
                        stroke:new Stroke({
                            width:2,
                            color:'#fff'
                        }),
                        offsetY:25
                    })
                }),
                id:'marker'
            })
            layer.setZIndex(10)
            mapInstance.addLayer(layer)
            mapInstance.getView().setZoom(18)
        }
    }, [isReady,lat]);
    return (
        <MapStyle.Wrap>
            <MapStyle.Address>{address}</MapStyle.Address>
            <MapStyle.Map id={'mapContainer'}></MapStyle.Map>

        </MapStyle.Wrap>
    );
};

export default OlMap;