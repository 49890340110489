import React from 'react';
import {MetaverseArea} from "../style";
import Solution01 from "../../components/solution/Solution01";
import Solution02 from "../../components/solution/Solution02";
import {useTranslation} from "react-i18next";
import {blankLinkNotion} from "../../utils/script";

const Metaverse = () => {
    const {t} = useTranslation('translation');
    return (
        <>

            <MetaverseArea.Wrap>
                <MetaverseArea.Title>{t('dtx.2.name')}</MetaverseArea.Title>
                <MetaverseArea.Desc>
                    {t('dtx.2.desc')}
                    <span dangerouslySetInnerHTML={{__html:t('dtx.2.subDesc')}}></span>
                </MetaverseArea.Desc>
                <MetaverseArea.Button type={'button'} onClick={blankLinkNotion}>{t('common.notionBtn')}</MetaverseArea.Button>

            </MetaverseArea.Wrap>

            <Solution01/>
            <Solution02/>
        </>
    );
};

export default Metaverse;