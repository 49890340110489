import React, {useRef} from 'react';
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

const TabArea = {
    Wrap:styled.ul`
      display:flex;align-items:center;justify-content:center;border-bottom:1px solid #dedede;
      @media screen and (max-width:1024px){
        display:none;
      }
    `,
    Link:styled.li`
      position:relative;line-height:83px;min-width:200px;font-size:20px;font-weight:400;color:#767676;text-align:center;cursor:pointer;
      &:after{position:absolute;left:50%;bottom:-1px;content:'';width:0;height:4px;background:#000;opacity:0;transition:.4s;}
      &.active{color:#1b1b1b;font-weight:600;
        &:after{left:0;width:100%;opacity:1;}
      }
      @media screen and (max-width:1280px){
        font-size:18px;min-width:180px;line-height:60px;
      }
    `
}

const Tab = ({list}) => {
    const ref = useRef();
    const navigate = useNavigate();
    const onClick = (path) => {
        navigate(path)
        document.getElementById('wrap').scrollTo({
            top: ref.current.offsetTop - 110,
            behavior: 'smooth'
        })
    }
    return (
        <TabArea.Wrap ref={ref}>
            {
                list.map(({name,tabName,link},index) => {
                    return (
                        <TabArea.Link className={link === window.location.pathname ? 'active' : undefined} key={index} onClick={() => onClick(link)}>{tabName ? tabName : name}</TabArea.Link>
                    )
                })
            }

        </TabArea.Wrap>
    );
};

export default Tab;