import React from 'react';
import {Route, Routes} from "react-router-dom";
import Visual from "../components/Visual";
import {INTRO_TEXT, MENU_LIST} from "../utils/common";
import Tab from "../components/Tab";
import Movements from "../pages/intro/Movements";
import History from "../pages/intro/History";
import Performance from "../pages/intro/Performance";
import Talent from "../pages/intro/Talent";
import Location from "../pages/intro/Location";
import visual from "../assets/images/visual/visual-intro.png"
import Header from "../components/Header";
import Footer from "../components/Footer";
import TabSelect from "../components/TabSelect";
import Partners from "../pages/intro/Partners";
import {useTranslation} from "react-i18next";

const IntroRoute = () => {
    const { t } = useTranslation('translation');
    const [menuList] = MENU_LIST();
    return (
        <div id={'wrap'}>
            <Header/>
            <Visual title={t('visual.0.name')} desc={t('visual.0.text')} src={visual}/>
            <Tab list={menuList[0].dep2}/>
            <TabSelect list={menuList[0].dep2}/>
            <Routes>
                <Route path={'/movements'} element={<Movements/>}/>
                <Route path={'/history'} element={<History/>}/>
                <Route path={'/partners'} element={<Partners/>}/>
                <Route path={'/performance'} element={<Performance/>}/>
                <Route path={'/talent'} element={<Talent/>}/>
                <Route path={'/location'} element={<Location/>}/>
            </Routes>
            <Footer/>
        </div>
    );
};

export default IntroRoute;