import {atom} from "recoil";

export const privacyState = atom({
    key: 'privacyState',
    default: false,
});

export const videoState = atom({
    key:'videoState',
    default:false
})