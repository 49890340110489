import React from 'react';
import styled from "styled-components";
import TalentCard from "../../components/TalentCard";
import {INTRO_TEXT} from "../../utils/common";
import {useTranslation} from "react-i18next";
import talent01 from "../../assets/images/intro/img-talent-01.png";
import talent02 from "../../assets/images/intro/img-talent-02.png";
import talent03 from "../../assets/images/intro/img-talent-03.png";

const TalentArea = {
    Wrap:styled.div`
      max-width:1530px;margin:0 auto;padding:130px 0 171px;text-align: center;
      @media screen and (max-width:1600px){
        padding: 130px 100px;
      }
      @media screen and (max-width:1280px){
        padding: 100px 40px;
      }
      @media screen and (max-width:768px){
        padding: 70px 30px;
      }
    `,
    Title:styled.h1`
      font-weight:600;font-size:44px;color:#000;text-align:center;
      @media screen and (max-width:1280px){
        font-size:38px
      }
      @media screen and (max-width:1024px){
        font-size:34px
      }
      @media screen and (max-width:768px){
        font-size:28px;
      }
      @media screen and (max-width:420px){
        width:223px;margin:0 auto;
      }
    `,
    List:styled.ul`
      display:flex;max-width: 1530px;margin:0 auto;padding-bottom:100px;
      @media screen and (max-width:1600px){
        padding: 0 100px 100px;
      }
      @media screen and (max-width:1280px){
        padding: 0 40px 80px;
      }
      @media screen and (max-width:1024px){
        flex-direction:column;
      }
      @media screen and (max-width:768px){
        padding:0 30px 70px;
      }
    `
}
const Talent = () => {
    const {t} = useTranslation('translation');
    const list = [
        {title:t('main.section04.item01.title'),desc:t('main.section04.item01.desc'),src:talent01},
        {title:t('main.section04.item02.title'),desc:t('main.section04.item02.desc'),src:talent02},
        {title:t('main.section04.item03.title'),desc:t('main.section04.item03.desc'),src:talent03},
    ]
    return (
        <>
            <TalentArea.Wrap>
                <TalentArea.Title>{t('about.talent.title')}</TalentArea.Title>

            </TalentArea.Wrap>
            <TalentArea.List>
                {
                    list.map(({src, desc,title},index) => {
                        return <TalentCard key={index} src={src} desc={desc} title={title}/>
                    })
                }

            </TalentArea.List>
        </>


    );
};

export default Talent;