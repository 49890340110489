import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import IcoPagiPrev from "../assets/images/ico-pagination-prev.svg"
import IcoPagiNext from "../assets/images/ico-pagination-next.svg"
import IcoPagiFirst from "../assets/images/ico-pagination-first.svg"
import IcoPagiLast from "../assets/images/ico-pagination-last.svg"

const Pagi = {
    Wrap:styled.div`
      display:flex;justify-content:center;align-items:center;margin-top:100px;
      @media screen and (max-width:1024px){
        margin-top:70px;
      }
      @media screen and (max-width:1024px){
        margin-top:57px;
      }
    `,
    NumGroup:styled.div`
      display:flex;margin:0 40px;
      @media screen and (max-width:768px){
        margin: 0 38px;
      }
    `,
    Prev:styled.button`
      width:32px;height:32px;background:url(${IcoPagiPrev})no-repeat center;
      &:disabled{opacity:.2;}
      @media screen and (max-width:768px){
        width:20px;height:20px;background-size:20px 20px;
      }
    `,
    Next:styled.button`
      width:32px;height:32px;background:url(${IcoPagiNext})no-repeat center;
      &:disabled{opacity:.2;}
      @media screen and (max-width:768px){
        width:20px;height:20px;background-size:20px 20px;
      }
    `,
    First:styled.button`
      width:32px;height:32px;background:url(${IcoPagiFirst})no-repeat center;
      &:disabled{opacity:.2;}
      @media screen and (max-width:768px){
        width:20px;height:20px;background-size:20px 20px;
      }
    `,
    Last:styled.button`
      width:32px;height:32px;background:url(${IcoPagiLast})no-repeat center;
      &:disabled{opacity:.2;}
      @media screen and (max-width:768px){
        width:20px;height:20px;background-size:20px 20px;
      }
    `,
    Num:styled.button`
      min-width:32px;height:32px;padding:0 2px;color:#a0a0a0;font-size:20px;font-weight:400;
      &:not(:last-child){margin-right:16px;}
      &.active{color:#000;}
      @media screen and (max-width:768px){
        min-width:20px;height:20px;font-size: 14px;
      }
    `
}

const Pagination = ({totalPages, pageInfo, setPageInfo}) => {
    const [startRow, setStartRow] = useState(1);
    const [pageGroup, setPageGroup] = useState(1);
    const [pageList, setPageList] = useState([]);
    useEffect(() => {
        setStartRow((pageGroup - 1) * pageInfo.size + 1)
    },[pageGroup]);

    useEffect(() => {   //endrow=2
        let endRow = pageGroup * pageInfo.size > totalPages ? totalPages : pageGroup * pageInfo.size;
        let newPageList = [];
        for (let i = startRow; i < endRow + 1; i++) {
            newPageList.push(i);
        }
        setPageList(newPageList);
    },[startRow, totalPages])

    let newData = {...pageInfo};
    const onFirst = () => {
        setPageGroup(1);
        newData.pageNum = 1;
        setPageInfo(newData);
    }
    const onPrev = () => {
        if (pageGroup === 1) return;
        setPageGroup(pageGroup - 1);
        newData.pageNum = (pageGroup - 1) * pageInfo.size
        setPageInfo(newData);
    }
    const onNext = () => {
        if (pageGroup * 5 > totalPages) return;
        setPageGroup(pageGroup + 1);
        newData.pageNum = (pageGroup * pageInfo.size) + 1
        setPageInfo(newData);
    }
    const onLast = () => {
        setPageGroup(Math.ceil(totalPages / pageInfo.size));
        newData.pageNum = totalPages
        setPageInfo(newData);
    }
    const onNum = (num) => {
        newData.pageNum = num
        setPageInfo(newData)
    }
    return (
        <Pagi.Wrap>
            <Pagi.First type={'button'} onClick={onFirst} disabled={totalPages < pageInfo.size || pageGroup === 1}></Pagi.First>
            <Pagi.Prev type={'button'} onClick={onPrev} disabled={totalPages < pageInfo.size || pageGroup === 1}></Pagi.Prev>
            <Pagi.NumGroup>
                {
                    pageList.map((num, index) => {
                        if(pageInfo.pageNum === num){
                            return <Pagi.Num key={index} type={'button'} className={'active'}>{num}</Pagi.Num>
                        }else {
                            return <Pagi.Num key={index} type={'button'} onClick={() => onNum(num)}>{num}</Pagi.Num>
                        }
                    })
                }
            </Pagi.NumGroup>
            <Pagi.Next type={'button'} onClick={onNext} disabled={pageGroup * 5 > totalPages - 1}></Pagi.Next>
            <Pagi.Last type={'button'} onClick={onLast} disabled={pageGroup * 5 > totalPages - 1}></Pagi.Last>

        </Pagi.Wrap>
    );
};

export default Pagination;