import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import ImgVisual from "../../assets/images/intro/bg-movements-01.png"
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import ImgPlay from "../../assets/images/ico-video-play.svg"

const TextArea = {
    Wrap:styled.div`
      padding:130px 0;text-align:center;
      @media screen and (max-width:1600px){
        padding: 130px 100px;
      }
      @media screen and (max-width:1280px){
        padding: 100px 40px;
      }
      @media screen and (max-width:768px){
        padding: 70px 30px;
      }
    `,
    Title:styled.h2`
      display:block;color:#000;font-size:44px;font-weight:500;margin-bottom:39px;word-break:keep-all;line-height:62px;
      
      @media screen and (max-width:1280px){
        font-size:38px;line-height:56px;
      }
      @media screen and (max-width:1024px){
        font-size:34px;line-height:48px;
      }
      @media screen and (max-width:768px){
        font-size:28px;line-height:42px;
      }
    `,
    Desc:styled.p`
      line-height: 38px;font-size:24px;color:#000;font-weight:400;word-break:keep-all;

      @media screen and (max-width:1280px){
        line-height: 35px;font-size:22px
      }
      @media screen and (max-width:1024px){
        line-height: 30px;font-size:19px
      }
      @media screen and (max-width:768px){
        line-height: 26px;font-size:16px
      }
    `
}
const Visual = {
    Wrap:styled.div`
      position:relative;background: url(${ImgVisual})no-repeat center;background-size:cover;
      &:before{position:absolute;left:0;top:0;z-index:1;width:100%;height:100%;content:'';background:rgba(0,0,0,.6);}
      @media screen and (max-width:1600px){
        padding: 0 100px;
      }
      @media screen and (max-width:1280px){
        padding: 0 40px;
      }
      @media screen and (max-width:768px){
        padding:0 30px;
      }
    `,
    Title:styled.h2`
      position:relative;z-index:2;display:block;line-height: 74px;color:#fff;font-size:52px;font-weight:600;margin-bottom:40px;text-align:center;
      padding-top:320px;word-break:keep-all;
      @media screen and (max-width:1280px){
        padding-top:200px;font-size:44px;line-height:66px;
      }
      @media screen and (max-width:1024px){
        padding-top:120px;font-size:38px;line-height:52px;
      }
      @media screen and (max-width:768px){
        padding-top:70px;font-size:30px;line-height:38px;
      }
    `,
    Desc:styled.p`
      position:relative;z-index:2;line-height: 40px;color:#fff;font-size:24px;font-weight:400;text-align:center;padding-bottom:346px;word-break:keep-all;
      @media screen and (max-width:1280px){
        padding-bottom:200px;font-size:21px;line-height:38px;
      }
      @media screen and (max-width:768px){
        padding-bottom:120px;font-size:20px;line-height:30px;
      }
      @media screen and (max-width:768px){
        padding-bottom:70px;font-size:16px;line-height:26px;
      }
    `
}

const Partner = {
    Wrap: styled.div`
      max-width:1530px;margin:0 auto;padding:120px 0 200px;
      @media screen and (max-width:1600px){
        padding:120px 100px 200px;
      }
      @media screen and (max-width:1280px){
        padding:100px 40px 150px;
      }
      @media screen and (max-width:1024px){
        padding:60px 40px 100px;
      }

      @media screen and (max-width:768px){
        padding:30px 30px 60px;
      }
    `,
    VideoArea:styled.div`
      position:relative;
    `,
    VideoPlay:styled.div`
      position:absolute;left:0;top:0;width:100%;height:100%;z-index:10;transition:.4s;
      background:${p => p.play ? "none" : `rgba(255,255,255,.2) url(${ImgPlay})no-repeat;`};
      background-position: center;
    `,
    Title:styled.strong`
      font-size:44px;color:#000;font-weight:600;display:block;margin-bottom: 30px;text-align: center;
      @media screen and (max-width:1280px){
        font-size:38px
      }
      @media screen and (max-width:1024px){
        font-size:34px
      }
      @media screen and (max-width:768px){
        font-size:28px
      }
    `,
    Desc:styled.p`
      font-size:24px;font-weight:400;display:block;color:#000;text-align: center;margin-bottom:90px;

      @media screen and (max-width:1280px){
        line-height: 35px;font-size:22px;margin-bottom:70px;
      }
      @media screen and (max-width:1024px){
        line-height: 30px;font-size:19px;margin-bottom:50px;
      }
      @media screen and (max-width:768px){
        line-height: 26px;font-size:16px;margin-bottom:30px;
      }
    `,
    List:styled.ul`
      display:flex;flex-wrap:wrap;
    `,
    Item:styled.li`
      height:260px;display:flex;align-items:center;justify-content:center;width:calc(25% - 22.5px);margin-right:30px;margin-top:30px;
      background:#fbfbfb;border-radius:10px;
      img{width:100%;}
      &:nth-child(4n){margin-right:0}
      &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4){margin-top:0}
      @media screen and (max-width:1024px){
        width:calc(33.3% - 20px);height:220px;
        &:nth-child(4n){margin-right:30px}
        &:nth-child(4){margin-top:30px}
        &:nth-child(3n){margin-right:0;}
      }
      @media screen and (max-width:768px){
        width:calc(50% - 10px);height:180px;margin-right:20px;margin-top:20px;
        &:nth-child(3n){margin-right:20px;}
        &:nth-child(2n){margin-right:0}
        &:nth-child(1), &:nth-child(2){margin-top:0;}
        &:nth-child(3){margin-top:20px;}
        &:nth-child(4){margin-top:20px;}
      }
      @media screen and (max-width:768px){
        height: 120px;
      }
    `
}

const Movements = () => {
    const {t} = useTranslation('translation');
    const [play, setPlay] = useState(false);
    return (
        <>
            <TextArea.Wrap>
                <TextArea.Title dangerouslySetInnerHTML={{__html:t('about.movements.title')}}></TextArea.Title>
                <TextArea.Desc dangerouslySetInnerHTML={{__html:t('about.movements.desc')}}></TextArea.Desc>
            </TextArea.Wrap>
            <Visual.Wrap>
                <Visual.Title dangerouslySetInnerHTML={{__html:t('about.movements.visualTitle')}}></Visual.Title>
                <Visual.Desc dangerouslySetInnerHTML={{__html:t('about.movements.visualDesc')}}></Visual.Desc>
            </Visual.Wrap>
            <Partner.Wrap>
                <Partner.VideoArea>
                    <Partner.VideoPlay play={play} onClick={() => setPlay(!play)}></Partner.VideoPlay>
                    <ReactPlayer id={'videoPlayer'} width={'100%'} height={'auto'} url={'/videos/brand_info.mp4'} controls={false} playing={play}/>
                </Partner.VideoArea>
            </Partner.Wrap>
        </>
    );
};

export default Movements;