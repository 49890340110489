import React from 'react';
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectFade, Navigation} from "swiper";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import Card from "../Card";
import {MAIN_TEXT} from "../../utils/common";
import IcoPrev from "../../assets/images/main/ico-pagi-prev.png";
import IcoNext from "../../assets/images/main/ico-pagi-next.png";


const Page = {
    Wrap: styled.div`
      width:100%;height:100%;
    `,
}

const SwiperCont = {
    Textarea:styled.div`
      flex:1;position:relative;
      >.inner{display:flex;flex-direction:column;justify-content:space-between;max-width:765px;padding:140px 100px 340px;height:100%;
        margin-left: auto;background:#fff;}
      @media screen and (max-width:1280px){
        >.inner{padding:140px 40px 340px 40px;}
      }
      @media screen and (max-width:1024px){
        flex:none;height:50%;
        >.inner{padding:0 40px;margin:0;max-width:none;}
      }
      @media screen and (max-width:1024px){
        >.inner{padding:0;}
      }
    `,
    ImageArea:styled.div`
      flex:1;display:flex;align-items:center;justify-content:center;
      background:${p => p.color ? p.color : '#fff'};
      img{width:100%;}
      @media screen and (max-width:1024px){
        flex:none;height:calc(50% - 64px);
        img{width:auto;height: 100%;}
      }
    `,
    Navi:styled.div`
      position:absolute;left:50%;bottom:100px;transform:translateX(-50%);display:flex;align-items:center;justify-content:center;background:#fff;
      >span{margin:0 73px;color:#000;font-size:16px;font-weight:400;}
      button{width:48px;height:48px;background-repeat: no-repeat;background-position: center;
        &.btnPrev2{background-image:url(${IcoPrev})};    
        &.btnNext2{background-image:url(${IcoNext})};    
        &.swiper-button-disabled{opacity:0.2}
      }
      @media screen and (max-width:1280px){
        >span{margin:0 30px;}
      }
      @media screen and (max-width:1024px){
        top:calc(200% - 56px);bottom:auto;width:100%;
        >span{margin:0 65px;}
      }
      @media screen and (max-width:768px){
        button{background-size:80%}
      }
    `

}

const Section04 = () => {
    const {list} = MAIN_TEXT();
    return (
        <Page.Wrap>
            <Swiper
                className="projectSwiper"
                navigation={{
                    nextEl: '.btnNext2',
                    prevEl: '.btnPrev2',
                    enabled:true
                }}
                effect={'fade'}
                allowTouchMove={false}
                modules={[Navigation,EffectFade]}
            >
                {
                    Object.keys(list.SECTION_04).map((i,index) => {
                        return(
                            <SwiperSlide key={index}>
                                <SwiperCont.ImageArea color={list.SECTION_04[i].color}><img src={list.SECTION_04[i].src} alt={list.SECTION_04[i].title}/></SwiperCont.ImageArea>
                                <SwiperCont.Textarea>
                                    <div className={'inner'}>
                                        <Card tag={list.SECTION_04[i].tag}
                                              info={list.SECTION_04[i].info}
                                              desc={list.SECTION_04[i].desc}
                                              title={list.SECTION_04[i].title}
                                        />
                                        <SwiperCont.Navi>
                                            <button type={'button'} className={'btnPrev2'}><span className={'hidden'}>prev</span></button>
                                            <span>{index + 1} / {Object.keys(list.SECTION_04).length}</span>
                                            <button type={'button'} className={'btnNext2'}><span className={'hidden'}>next</span></button>
                                        </SwiperCont.Navi>
                                    </div>
                                </SwiperCont.Textarea>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </Page.Wrap>
    );
};


export default Section04;