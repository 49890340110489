import React from 'react';
import styled from "styled-components";

const CardArea = {
    Wrap:styled.div`
      text-align:left;
      @media screen and (max-width:1024px){
        flex:1;display:flex;flex-direction:column;align-items:center;justify-content: flex-start;text-align: center;padding:0 40px;margin-top:90px;
      }
      @media screen and (max-width:768px){
        padding:0 30px;margin-top:70px;
      }
    `,
    Tag:styled.span`
      color:#3100ff;font-size:20px;display:block;margin-bottom: 20px;font-weight:600;
      @media screen and (max-width:768px){
        font-size:16px;margin-bottom:14px;
      }
    `,
    Info:styled.strong`
      display:block;font-size:48px;color:#000;line-height:60px;
      margin-bottom: 60px;
      font-weight:500;letter-spacing:-2px;
      @media screen and (max-width:1600px){
        letter-spacing:-2px;
      }
      @media screen and (max-width: 1280px){
        font-size:37px;height: auto; margin-bottom: 60px;word-break: keep-all;line-height:60px;
      }
      @media screen and (max-width: 1024px){
        margin-bottom:40px;line-height:48px;
      }
      @media screen and (max-width: 768px){
        font-size:24px;line-height:32px;margin-bottom:26px;
      }
    `,
    Title:styled.strong`
      display:block;font-size:36px;color:#000;line-height:70px;margin-bottom:30px;font-weight:600;
      @media screen and (max-width: 1280px){
        font-size:30px;
      }
      @media screen and (max-width: 1024px){
        font-size:27px;line-height:36px;
      }
      @media screen and (max-width: 768px){
        font-size:20px;margin-bottom:16px;line-height:20px;
      }
    `,
    Desc:styled.p`
      color:#000;font-size:24px;
      line-height:${p => p.height === 'auto' ? '60px' : '30px'};
      font-weight:400;
      @media screen and (max-width: 1280px){
        font-size:20px;word-break: keep-all;line-height:26px;
      }
      @media screen and (max-width: 768px){
        font-size:16px;line-height: 22px;
        br{display: none}
      }
    `

}

const Card = ({tag,info,title,desc,height}) => {
    return (
        <CardArea.Wrap>
            <div>
                <CardArea.Tag>{tag}</CardArea.Tag>
                <CardArea.Info height={height} dangerouslySetInnerHTML={{__html:info}}></CardArea.Info>
            </div>
            <div>
                <CardArea.Title>{title}</CardArea.Title>
                <CardArea.Desc dangerouslySetInnerHTML={{__html:desc}} height={height}></CardArea.Desc>
            </div>
        </CardArea.Wrap>
    );
};

export default Card;