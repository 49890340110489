import React, {forwardRef} from 'react';
import {LabelArea} from "./style";
import {useTranslation} from "react-i18next";

const LabelSelect = forwardRef(({id, label, require, name, list, onChange},ref) => {
    const {t} = useTranslation('translation');
    return (
        <LabelArea.Wrap>
            <LabelArea.Label htmlFor={id} className={require ? 'req' : undefined}>{label}</LabelArea.Label>
            <LabelArea.Select id={id} name={name} onChange={onChange} defaultValue={null} ref={ref}>
            {list.map(({name, value},index) => {
                return(
                    <option key={index} value={value}>{name}</option>
                )
            })}
            </LabelArea.Select>
        </LabelArea.Wrap>
    );
});

export default LabelSelect;