import React from 'react';
import styled from "styled-components";

const TalentCardStyle = {
    Wrap:styled.div`
      flex:1;padding:37px 0 57px;box-shadow: 5px 3px 15px 0 rgba(37, 15, 129, 0.09);background:#fff;border-radius:12px;
      &:not(:last-child){margin-right:30px;}
      @media screen and (max-width:1024px){
        &:not(:last-child){margin-right:0;margin-bottom:60px;}
      }
      @media screen and (max-width:768px){
        padding:20px;
      }
    `,
    Image:styled.div`
      margin-bottom:15px;text-align: center;
      @media screen and (max-width:768px){
        //img{width:100%;}
      }
    `,
    Title:styled.strong`
      display:block;font-size:32px;color:#000;font-weight:600;margin-bottom:20px;text-align:center;padding:0 40px;
      @media screen and (max-width:1024px){
        font-size:30px;margin-bottom:18px;padding:0 20px;
      }
      @media screen and (max-width:768px){
        font-size:24px;margin-bottom:16px;padding:0 15px;
      }
    `,
    Desc:styled.p`
      font-size:20px;font-weight:400;line-height:32px;text-align:center;color:#000;word-break:keep-all;padding:0 40px;
      @media screen and (max-width:1024px){
        font-size:18px;line-height:28px;padding:0 20px;
      }
      @media screen and (max-width:768px){
        font-size:16px;line-height:24px;padding:0 15px;
      }
    `
}
const TalentCard = ({src,title,desc}) => {
    return (
        <TalentCardStyle.Wrap>
            <TalentCardStyle.Image>
                <img src={src} alt={title}/>
            </TalentCardStyle.Image>
            <TalentCardStyle.Title>{title}</TalentCardStyle.Title>
            <TalentCardStyle.Desc dangerouslySetInnerHTML={{__html:desc}}></TalentCardStyle.Desc>
        </TalentCardStyle.Wrap>
    );
};

export default TalentCard;