import styled from "styled-components";


export const Popup = {
    Dimmed:styled.div`
      position:fixed;left:0;top:0;width:100%;height:100%;background:rgba(0,0,0,.39);z-index:1000;
    `,
    Wrap:styled.div`
      position:fixed;left:50%;top:50%;z-index:1000;transform:translate(-50%,-50%);
      width:${p => p.width ? p.width : "1010px"};
      height:${p => p.height ? p.height : "calc(100% - 100px)"};
      border-radius:12px;background:#fff;
      @media screen and (max-width:1024px){
        width:100%;height:100%;
      }
    `,
    Head:styled.div`
      display:flex;align-items:center;justify-content:space-between;height:88px;padding:0 48px;border-bottom:1px solid #d8d8d8;
      @media screen and (max-width:1280px){
        height:78px;
      }
      @media screen and (max-width:1024px){
        height: 60px;padding:0 40px;
      }
      @media screen and (max-width:768px){
        height: 50px;padding:0 30px;
      }
    `,
    Tag:styled.span`
      display:inline-block;width:114px;height:38px;line-height:38px;border:1px solid #3100ff;box-sizing:border-box;
      border-radius:50px;font-size:16px;color:#3100ff;text-align:center;font-weight:500;
      @media screen and (max-width:768px){
        width:100px;height:34px;font-size:14px;
      }
    `,
    Close:styled.button`
      position:relative;width:24px;height:24px;overflow:hidden;
      &:before,&:after{display:block;content:'';position:absolute;left:50%;top:50%;width:30px;height:1px;background: #74747b; }
      &:before{transform:translate(-50%,-50%) rotate(45deg);}
      &:after{transform:translate(-50%,-50%) rotate(-45deg);}
    `,
    TitleRow:styled.div`
      display: flex;align-items: center;width:calc(100% - 40px);
    `,
    Title:styled.strong`
      font-size:20px;color:#1b1b1b;font-weight: 500;margin-left:28px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width:calc(100% - 100px);
      @media screen and (max-width:1024px){
        font-size:18px;
      }
      @media screen and (max-width:768px){
        font-size:16px;
      }
    `,
    Content:styled.div`
      height:${p => p.height ? p.height : 'calc(100% - 88px)'};
      >.inner{padding:40px 48px 60px;}
      @media screen and (max-width:1024px){
        height: calc(100% - 60px);overflow:auto;
        >.inner{padding-left:40px;padding-right:40px;}
      }
      @media screen and (max-width:768px){
        height: calc(100% - 50px);
        >.inner{padding-left:30px;padding-right:30px;}
      }
      
    `,
    LabelText:styled.div`
      display:flex;align-items:flex-start;line-height: 32px;
      &:not(:first-child){margin-top:26px;}
      @media screen and (max-width:1280px){
        &:not(:first-child){margin-top:20px;}
      }
      @media screen and (max-width:1024px){
        line-height: 28px;
        &:not(:first-child){margin-top:16px;}
      }
      @media screen and (max-width:768px){
        line-height: 26px;
        &:not(:first-child){margin-top:12px;}
      }
      
    `,
    Label:styled.span`
      font-size:20px;font-weight:500;color:#000;min-width:142px;
      @media screen and (max-width:1280px){
        font-size:18px;min-width:100px;
      }
      @media screen and (max-width:1024px){
        font-size:16px;
      }
      @media screen and (max-width:768px){
        font-size:14px;
      }
    `,
    Text:styled.p`
      font-size:20px;font-weight:400;color:#000;word-break:keep-all;
      @media screen and (max-width:1280px){
        font-size:18px;
      }
      @media screen and (max-width:1024px){
        font-size:16px;
      }
      @media screen and (max-width:768px){
        font-size:14px;
      }
    `,
    ImageBox:styled.div`
      text-align:center;margin-top:52px;
      img{border:1px solid #d8d8d8;border-radius:12px;}
      @media screen and (max-width:1280px){
        margin-top:32px;
      }
      @media screen and (max-width:768px){
        img{width:100%;}
      }
      ${p => p.noImage && ' margin:52px auto 0;img{padding:100px 50px;}'}
    `,
    Privacy:styled.p`
      color:#000;font-size:20px;font-weight:400;line-height: 34px;margin-bottom:20px;
      &:first-child{margin-bottom:40px;}
      &:last-child{margin-bottom:0;}
      @media screen and (max-width:1280px){
        font-size:18px;margin-bottom:18px;line-height: 32px;
        &:first-child{margin-bottom:36px;}
      }
      @media screen and (max-width:1024px){
        font-size:16px;margin-bottom:16px;line-height: 30px;
        &:first-child{margin-bottom:32px;}
      }
      @media screen and (max-width:768px){
        font-size:14px;margin-bottom:14px;line-height: 28px;
        &:first-child{margin-bottom:28px;}
      }
    `

}