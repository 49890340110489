import React, {useCallback, useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectFade, FreeMode, Pagination, Mousewheel,Scrollbar} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/effect-fade";
import Section01 from "../components/main/Section01";
import Section02 from "../components/main/Section02";
import Section03 from "../components/main/Section03";
import Section04 from "../components/main/Section04";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ReactFullpage from "@fullpage/react-fullpage";
const Main = () => {
    useEffect(() => {
        document.getElementsByClassName('fp-watermark')[0].remove()
        document.getElementById('fp-nav').remove()
    },[])
    const anchors = ["s1", "s2", "s3","s4","s5"];
    const [className, setClassName] = useState('s1');
    const [size, setSize] = useState(window.innerWidth);
    const onResize = () => {
        setSize(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize',onResize);
        return () => window.removeEventListener('resize', onResize);
    })
    return (
        <>
            {size <= 1024 && <Header className={className}/>}
            <ReactFullpage
                anchors={anchors}
                navigation
                navigationTooltips={anchors}
                onLeave={(origin, destination, direction) => {
                    //console.log("onLeave event", { origin, destination, direction });
                    setClassName(destination.anchor);
                }}
                render={({ state, fullpageApi }) => {
                    //console.log("render prop change", state, fullpageApi); // eslint-disable-line no-console

                    return (
                        <>
                            <div className={'section s1'} style={{height:'100%'}}>
                                <Swiper
                                    autoplay={{
                                        delay:5000
                                    }}
                                    effect={'fade'}
                                    pagination={{
                                        clickable:true
                                    }}
                                    allowTouchMove={false}
                                    modules={[Autoplay,EffectFade,FreeMode,Pagination]}
                                    className="mainSwiper"
                                    onAfterInit={(swiper) => {
                                        const newBtn = document.createElement('button');
                                        const span = document.createElement('span');
                                        newBtn.type = 'button';
                                        newBtn.className = 'btnAutoPlay'
                                        newBtn.onclick = () => {
                                            if(newBtn.className === 'btnAutoPlay'){
                                                newBtn.className = 'btnAutoPlay pause'
                                                swiper.autoplay.stop()
                                            }else{
                                                newBtn.className = 'btnAutoPlay'
                                                swiper.autoplay.start()
                                            }
                                        }
                                        newBtn.appendChild(span)
                                        document.querySelector('.mainSwiper .swiper-pagination').appendChild(newBtn)
                                    }}

                                >
                                    {size > 1024 && <Header/>}
                                    <SwiperSlide className={'main01'}>
                                        <Section01/>
                                    </SwiperSlide>
                                    <SwiperSlide className={'main02'}>
                                        <Section01/>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            <div className={'section s2'}><Section02/></div>
                            <div className={'section s3'}><Section03/></div>
                            <div className={'section s4'}><Section04/></div>
                            <div className={'section s5 fp-auto-height'}><Footer type={'fullpage'} top={fullpageApi}/></div>
                        </>
                    );
                }}
            >




            </ReactFullpage>
        </>
    );
};

export default Main;