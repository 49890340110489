import React from 'react';
import {LabelArea} from "./style";

const LabelInput = ({id, label, type, require, name, placeholder, onChange, value}) => {
    return (
        <LabelArea.Wrap>
            <LabelArea.Label htmlFor={id} className={require ? 'req' : undefined} >{label}</LabelArea.Label>
            <LabelArea.Input type={type ? type : 'text'} id={id} name={name} onChange={onChange} placeholder={placeholder} value={value}/>
        </LabelArea.Wrap>
    );
};

export default LabelInput;