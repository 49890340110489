import React, {useRef, useState} from 'react';
import {InquiryArea} from "../style";
import LabelInput from "../../components/LabelInput";
import LabelSelect from "../../components/LabelSelect";
import LabelTextarea from "../../components/LabelTextarea";
import {LabelArea} from "../../components/style";
import Radio from "../../components/Radio";
import {useMutation} from "@tanstack/react-query";
import {postContact} from "../../service/api/common";
import {useTranslation} from "react-i18next";

const Online = () => {
    const {t} = useTranslation('translation');
    const radRef = useRef([])
    const selRef = useRef();
    const [form, setForm] = useState({
        contactType:'SOLUTION',
        serviceType:'',
        name:'',
        department:'',
        position:'',
        contact:'',
        email:'',
        contents:''
    })
    const selList = [
        {name:t('online.select.0'), value:''},
        {name:t('online.select.1'), value:'디지털트윈엑스'},
        {name:t('online.select.2'), value:'데이터기반 안전관리 책임제'},
        {name:t('online.select.3'), value:'건설자재 물류추적 시스템'},
        {name:t('online.select.4'), value:'연약지반 개량공사 관리 시스템'},
        {name:t('online.select.5'), value:'디지털트윈엑스 AR'},
        {name:t('online.select.6'), value:'2D도면기반 3차원 데이터 자동변환'},
        {name:t('online.select.7'), value:'파라메트릭 3D 모델링 자동화'},
    ]
    const contact = useMutation(() => postContact(form),{
        onSuccess:({data}) => {
            alert(data.message);
            setForm({
                contactType: 'SOLUTION',
                serviceType:'',
                name:'',
                department:'',
                position:'',
                contact:'',
                email:'',
                contents:''
            })
            radRef.current[0].checked = true
            radRef.current[1].checked = false
            radRef.current[2].checked = false
            selRef.current.value = '1'


        },
        onError:(err) => {
            alert(err.data.message)
        }
    });
    const onChange = (e) => {
        const {name, value} = e.target;
        setForm({
            ...form,
            [name]:value
        })
    }
    const onSubmit = () => {
        if(form.serviceType && form.name && form.department && form.position && form.contact && form.email){
            contact.mutate(form)
        }else{
            alert('* 값은 필수 입력입니다.')
        }
    }
    return (
        <InquiryArea.Wrap>
            <InquiryArea.Title>{t('online.title')}</InquiryArea.Title>
            <InquiryArea.Desc>{t('online.desc')}</InquiryArea.Desc>
            <InquiryArea.Form>
                <LabelArea.Wrap>
                    <LabelArea.Label className={'req'}>{t('online.label.0.question')}</LabelArea.Label>
                    <LabelArea.Row>
                        <Radio label={t('online.label.0.sel01')} id={'rad01'} name={'contactType'} checked={true} value={'SOLUTION'} ref={el => radRef.current[0] = el} onChange={(e) => onChange(e,'radio')}/>
                        <Radio label={t('online.label.0.sel02')} id={'rad02'} name={'contactType'} value={'BUSINESS'} ref={el => radRef.current[1] = el} onChange={(e) => onChange(e,'radio')}/>
                        <Radio label={t('online.label.0.sel03')} id={'rad03'} name={'contactType'} value={'OTHERS'} ref={el => radRef.current[2] = el} onChange={(e) => onChange(e,'radio')}/>
                    </LabelArea.Row>
                </LabelArea.Wrap>
                <LabelSelect require={true} label={t('online.label.1.question')} id={'serviceType'} name={'serviceType'} value={form.serviceType} onChange={onChange} ref={selRef} list={selList}/>
                <LabelInput require={true} label={t('online.label.2.question')} id={'name'} name={'name'} value={form.name} placeholder={t('online.label.2.placeholder')} onChange={onChange}/>
                <LabelInput label={t('online.label.3.question')} id={'department'} name={'department'} value={form.department} placeholder={t('online.label.3.placeholder')} onChange={onChange}/>
                <LabelInput label={t('online.label.4.question')} id={'position'} name={'position'} value={form.position} placeholder={t('online.label.4.placeholder')} onChange={onChange}/>
                <LabelInput require={true} label={t('online.label.5.question')} id={'contact'} name={'contact'} value={form.contact} placeholder={t('online.label.5.placeholder')} onChange={onChange}/>
                <LabelInput require={true} label={t('online.label.6.question')} id={'email'} name={'email'} value={form.email} placeholder={t('online.label.6.placeholder')} onChange={onChange}/>
                <LabelTextarea label={t('online.label.7.question')} require={true} id={'contents'} name={'contents'} value={form.contents} placeholder={t('online.label.7.placeholder')} onChange={onChange}/>
                <InquiryArea.Button type={'button'} onClick={onSubmit}>{t('online.button')}</InquiryArea.Button>
            </InquiryArea.Form>

        </InquiryArea.Wrap>
    );
};

export default Online;