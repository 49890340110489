import React from 'react';
import {Popup} from "./style";
import {useTranslation} from "react-i18next";
import {useRecoilState} from "recoil";
import {privacyState} from "../store/common";


const PopupPrivacy = () => {
    const [privacy, setPrivacy] = useRecoilState(privacyState);
    const onPrivacy = () => {
        setPrivacy(!privacy)
    }
    const {t} = useTranslation('translation')
    return (
        <>
            <Popup.Dimmed></Popup.Dimmed>
            <Popup.Wrap width={'600px'} height={'auto'}>
                <Popup.Head>
                    <Popup.TitleRow>
                        <Popup.Title style={{marginLeft:0}}>{t('common.privacy.title')}</Popup.Title>
                    </Popup.TitleRow>
                    <Popup.Close type={'button'} onClick={onPrivacy}><span className={'hidden'}>닫기</span></Popup.Close>
                </Popup.Head>
                <Popup.Content style={{height:"auto"}}>
                    <div className={'inner'}>
                        <Popup.Privacy>{t('common.privacy.content.0')}</Popup.Privacy>
                        <Popup.Privacy>{t('common.privacy.content.1')}</Popup.Privacy>
                        <Popup.Privacy>{t('common.privacy.content.2')}</Popup.Privacy>
                        <Popup.Privacy>{t('common.privacy.content.3')}</Popup.Privacy>
                        <Popup.Privacy>{t('common.privacy.content.4')}</Popup.Privacy>
                    </div>
                </Popup.Content>
            </Popup.Wrap>
        </>
    );
};

export default PopupPrivacy;