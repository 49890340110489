import React from 'react';
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectFade, Navigation} from "swiper";
import "swiper/css/navigation";
import Image01 from "../../assets/images/visual/item-metaverse-02.png"
import IcoPrev from "../../assets/images/main/ico-pagi-prev.png"
import IcoNext from "../../assets/images/main/ico-pagi-next.png"
import Card from "../Card";
import {DTX_TEXT} from "../../utils/common";
import {useTranslation} from "react-i18next";


const Page = {
    Wrap: styled.div`
      width:100%;
    `,
}

const SwiperCont = {
    SwiperArea: styled.div`
      .swiper{
        .swiper-slide{display:flex;height:800px}
      }
      &.noSwiper{
        display:flex;height:800px;
      }
      @media screen and (max-width:1600px){
        &.noSwiper{
          height:720px;
        }
      }
      @media screen and (max-width:1280px){
        &.noSwiper{
          height:540px;
        }
      }
      @media screen and (max-width:1024px){
        &.noSwiper{
          flex-direction:column-reverse;height:auto;
        }
      }
    `,
    Textarea:styled.div`
      flex:1;position:relative;
      >.inner{display:flex;flex-direction:column;justify-content:space-between;max-width:765px;padding-top:97px;margin-left: auto;background:#fff;
        p{line-height:36px;padding-right:40px;
          span{display:flex;font-weight:400;color:#000;
            &:before{content: '-';padding-right:10px;}
            &:not(:last-child){margin-bottom:20px;}
          }
        }
      }
      @media screen and (max-width:1600px){
        text-align: center;
        >.inner{padding-top:40px;
          >div{
            span{text-align:center;}
          }
          strong{text-align:center;line-height:50px;}
          p{text-align:center;padding-left:40px;
            span{text-align:left;
              &:not(:last-child){margin-bottom:20px;}
            }
          }
        }
        @media screen and (max-width:1280px){
          >.inner{
            strong{margin-bottom:40px;}
            p{line-height:30px;
              span{
                &:not(:last-child){margin-bottom:16px;}
              }
            }
          }
        }
      @media screen and (max-width:1024px){
        margin-bottom:20px;
        >.inner{max-width:none;padding-top:40px;
          >div{margin-top:0;}
          strong{margin-bottom:20px;}
          p{padding:0;line-height:26px;
            span{
              &:not(:last-child){
                margin-bottom:20px;
              }
            }
          }
        }
      }
    `,
    ImageArea:styled.div`
      flex:1;background:linear-gradient(to right top,rgba(241,185,163),rgba(250,227,203));display:flex;align-items:center;justify-content:center;
      img{width:100%;height:100%}
      @media screen and (max-width:768px){
        flex:none;
      }
    `,

}


const Solution02 = () => {
    const {t} = useTranslation('translation');
    const data = {
        info:t('dtx.2.section02.title'),
        desc:`<span>${t('dtx.2.section02.content.0')}</span><span>${t('dtx.2.section02.content.1')}</span><span>${t('dtx.2.section02.content.2')}</span><span>${t('dtx.2.section02.content.3')}</span>`
    }
    return (
        <Page.Wrap>
            <SwiperCont.SwiperArea className={'noSwiper'}>
                <SwiperCont.ImageArea><img src={Image01} alt={DTX_TEXT.SOLUTION02.info}/></SwiperCont.ImageArea>
                <SwiperCont.Textarea>
                    <div className={'inner'}>
                        <Card tag={DTX_TEXT.SOLUTION02.item01.tag}
                              info={data.info}
                              desc={data.desc}
                              height={'auto'}
                        />
                    </div>
                </SwiperCont.Textarea>
            </SwiperCont.SwiperArea>
        </Page.Wrap>
    );
};

export default Solution02;