import React from 'react';
import styled from "styled-components";
import noImage from "../assets/images/intro/img-no-image.png"
const CertArea = {
    Wrap: styled.div`
      width:calc(25% - 34.5px);margin-right:46px;margin-top:120px;cursor: pointer;
      &:nth-child(4n){margin-right:0;}
      &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4){margin-top:0;}
      @media screen and (max-width:1600px){
        width:calc(33.3% - 31px);
        &:nth-child(4n){margin-right:46px;}
        &:nth-child(4){margin-top:120px;}
        &:nth-child(3n){margin-right:0;}
        
      }
      @media screen and (max-width:1280px){
        margin-right:30px;
        &:nth-child(4n){margin-right:30px;}
      }
      @media screen and (max-width:1024px){
        margin-top:80px;width:calc(50% - 15px);
        &:nth-child(3n){margin-right:30px;}
        &:nth-child(3), &:nth-child(4){margin-top:80px;}
        &:nth-child(2n){margin-right:0;}
      }
      @media screen and (max-width:768px){
        width: 100%;margin-right:0;margin-top:60px;
        &:nth-child(3n){margin-right:0;}
        &:nth-child(2), &:nth-child(3), &:nth-child(4){margin-top:60px;}
        
      }
    `,
    Image:styled.div`
      display:flex;align-items:center;justify-content:center;height:478px;border:1px solid #d8d8d8;border-radius:12px;overflow:hidden;box-sizing:border-box;
      img{width:calc(100% - 20px);height:calc(100% - 20px)}
      &.noImage{
        img{width:auto;height:auto;}
      }
      @media screen and (max-width:1280px){
        height:360px;
      }

      @media screen and (max-width:1024px){
      }
      @media screen and (max-width:768px){
        height:300px;
        img{width: auto; height:100%;}
      }
    `,
    Tag:styled.span`
      display:inline-block;min-width:114px;height:38px;line-height: 38px;border:1px solid #3100ff;border-radius:50px;
      box-sizing:border-box;color:#3100ff;font-size:16px;font-weight:500;text-align: center;margin:28px 0 16px;
      @media screen and (max-width:768px){
        font-size:14px;min-width:100px;height:34px;line-height:34px;margin:12px 0;
      }
    `,
    Desc:styled.p`
      color:#1b1b1b;font-size:20px;font-weight:500;line-height:34px;word-break:keep-all;
      @media screen and (max-width:1024px){
        font-size:18px;line-height:30px;
      }
      @media screen and (max-width:768px){
        font-size:16px;line-height:24px;
      }
    `

}

const Cert = ({src, tag, desc, onClick}) => {
    return (
        <CertArea.Wrap onClick={onClick}>
            <CertArea.Image className={src ? undefined : 'noImage'}>
                {src ? <img src={src} alt={tag}/> : <img src={noImage} alt={'이미지가 없습니다'}/>}
            </CertArea.Image>
            <CertArea.Tag>{tag}</CertArea.Tag>
            <CertArea.Desc>{desc}</CertArea.Desc>
        </CertArea.Wrap>
    );
};

export default Cert;