import React from 'react';
import {Route, Routes} from "react-router-dom";
import Visual from "../components/Visual";
import {MENU_LIST, NEWS_TEXT} from "../utils/common";
import Tab from "../components/Tab";
import NewsMedia from "../pages/news/NewsMedia";
import Promotion from "../pages/news/Promotion";
import visual from "../assets/images/visual/visual-news.png"
import Header from "../components/Header";
import Footer from "../components/Footer";
import TabSelect from "../components/TabSelect";
import {useTranslation} from "react-i18next";

const NewsRoute = () => {
    const { t } = useTranslation('translation');
    const [menuList] = MENU_LIST();
    return (
        <div id={'wrap'}>
            <Header/>
            <Visual title={t('visual.3.name')} desc={t('visual.3.text')} src={visual}/>
            <Tab list={menuList[3].dep2}/>
            <TabSelect list={menuList[3].dep2}/>
            <Routes>
                <Route path={'/news-media'} element={<NewsMedia/>}/>
                <Route path={'/promotion'} element={<Promotion/>}/>
            </Routes>
            <Footer/>
        </div>
    );
};

export default NewsRoute;