import React from 'react';
import styled from "styled-components";

const VisualArea = {
    Wrap:styled.div`
      position:relative;width:100%;height:646px;display:flex;align-items:center;justify-content:center;flex-direction:column;
      background-repeat: no-repeat; background-position:center;background-size:cover;
      background-image:${p => p.src ? 'url('+p.src+')' : 'none'};
      &:before{position:absolute;left:0;top:0;content:'';width:100%;height:100%;z-index:1;background:rgba(0,0,0,.4);}
      @media screen and (max-width:1280px){
        height: 520px;padding:0 100px;
      }
      @media screen and (max-width:1024px){
        height: 448px;padding:129px 40px 0;
        justify-content:flex-start;
      }
      @media screen and (max-width:768px){
        background-size:auto calc(100% + 100px);
      }
    `,
    Title:styled.h1`
      position:relative;z-index:10;font-size:52px;color:#fff;font-weight:600;margin-bottom:20px;
      @media screen and (max-width:1280px){
        font-size:48px;
      }
      @media screen and (max-width:1024px){
        font-size:44px;margin-bottom:40px;
      }
      @media screen and (max-width:768px){
        font-size:36px;
      }
    `,
    Desc:styled.p`
      position:relative;z-index:10;font-size:24px;color:#fff;font-weight:500;text-align:center;line-height:38px;word-break:keep-all;
      @media screen and (max-width:1280px){
        font-size:21px;line-height:34px;
      }
      @media screen and (max-width:1024px){
        font-size:18px;line-height:30px;
      }
      @media screen and (max-width:768px){
        font-size:16px;line-height:26px;
      }
    `
}
const Visual = ({title, desc, src}) => {
    return (
        <VisualArea.Wrap src={src}>
            <VisualArea.Title>{title}</VisualArea.Title>
            <VisualArea.Desc dangerouslySetInnerHTML={{__html:desc}}></VisualArea.Desc>
        </VisualArea.Wrap>
    );
};

export default Visual;