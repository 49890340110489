import React from 'react';
import {Route, Routes} from "react-router-dom";
import Visual from "../components/Visual";
import {SOLUTION_TEXT, MENU_LIST} from "../utils/common";
import Tab from "../components/Tab";
import Cmls from "../pages/solution/Cmls";
import Sms from "../pages/solution/Sms";
import Pbd from "../pages/solution/Pbd";
import Dcm from "../pages/solution/Dcm";
import Soil from "../pages/solution/Soil";
import visual from "../assets/images/visual/visual-solution.png"
import Header from "../components/Header";
import Footer from "../components/Footer";
import TabSelect from "../components/TabSelect";
import {useTranslation} from "react-i18next";

const SolutionRoute = () => {
    const { t } = useTranslation('translation');
    const [menuList] = MENU_LIST();
    return (
        <div id={'wrap'}>
            <Header/>
            <Visual title={t('visual.2.name')} desc={t('visual.2.text')} src={visual}/>
            <Tab list={menuList[2].dep2}/>
            <TabSelect list={menuList[2].dep2}/>
            <Routes>
                <Route path={'/cmls'} element={<Cmls/>}/>
                <Route path={'/sms'} element={<Sms/>}/>
                <Route path={'/pbd'} element={<Pbd/>}/>
                <Route path={'/dcm'} element={<Dcm/>}/>
                <Route path={'/soil'} element={<Soil/>}/>
            </Routes>
            <Footer/>
        </div>
    );
};

export default SolutionRoute;