import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import HistoryImage from "../../assets/images/intro/bg-history.png"
import HistoryImageM from "../../assets/images/intro/bg-history-m.png"
import {INTRO_TEXT} from "../../utils/common";
import {useTranslation} from "react-i18next";


const HistoryArea = {
    Wrap: styled.div`
      background:url(${HistoryImage})no-repeat right -93px top;
      @media screen and (max-width:1280px){
        background-size:500px;
      }
      @media screen and (max-width:1024px){
        background:url(${HistoryImageM})no-repeat 155px 30px;
      }
    `,
    Contain:styled.div`
      max-width:1530px;margin:0 auto;padding-bottom:120px;
      @media screen and (max-width:1600px){
        padding:0 100px 100px;
      }
      @media screen and (max-width:1280px){
        padding:0 40px 40px;
      }
      @media screen and (max-width:768px){
        padding:0 30px 40px;
      }
    `,
    Title:styled.h1`
      font-size:48px;color:#1b1b1b;font-weight:500;padding:160px 0 188px;word-break:keep-all;
      span{color:#3100ff;font-weight:500;}

      @media screen and (max-width:1280px){
        font-size: 40px;padding:130px 0;
      }
      @media screen and (max-width:1024px){
        font-size: 36px;padding:100px 0;
      }
      @media screen and (max-width:768px){
        font-size: 28px;width:200px;line-height:44px;padding:70px 0;
      }
    `,
    Year:styled.strong`
      color:#ebe9f4;font-size:124px;font-weight:400;text-align:right;
      @media screen and (max-width:1280px){
        font-size: 100px;
      }
      @media screen and (max-width:1024px){
        font-size: 60px;margin-bottom:10px;
      }
      @media screen and (max-width:1024px){
        font-size: 28px;
      }
    `,
    List:styled.ul`
      ;
    `,
    Item:styled.li`
      display:flex;opacity:0.4;justify-content: space-between;transition:.4s;
      >strong{transition:.4s;}
      &.active{opacity:1;
        >strong{color:#3100ff;}
      }
      @media screen and (max-width:1024px){
        flex-direction:column;align-items:flex-start;
        &:not(:last-child){margin-bottom:88px;}
      }
      
    `,
    Dep2:styled.ul`
      width:880px;
      li{display:flex;border-bottom:1px solid #dedede;padding:40px 0;opacity:.6;
          strong{width:130px;color:#3100ff;font-size:24px;font-weight:400;}
          >div{flex:1;}
          p{color:#1b1b1b;font-size:24px;font-weight:400;word-break:keep-all;width:100%;
            &:not(:last-child){margin-bottom:30px;}
          }
      }
      @media screen and (max-width:1600px){
        width: 650px;
      }
      @media screen and (max-width:1280px){
        li{padding:35px 0;
          strong{font-size:22px;width:120px;}
          p{font-size:22px;
            &:not(:last-child){margin-bottom:20px;}
          }
        }
      }
      @media screen and (max-width:1024px){
        width:100%;
        li{padding:25px 0;
          strong{font-size:20px;width:100px;}
          p{font-size:20px;
            &:not(:last-child){margin-bottom:16px;}
          }
        }
      }
      @media screen and (max-width:768px){
        li{padding:20px 0;
          strong{font-size:16px;width:85px;min-width: 70px;}
          p{font-size:16px;
            &:not(:last-child){margin-bottom:12px;}
          }
        }
      }
    `
}

const History = () => {
    const {t} = useTranslation('translation');
    const scrollRef = useRef();
    const list = [
        {year:2021, active:true, dep2:[
                {month:t('about.history.2021.6.month'), dep3:[t('about.history.2021.6.content.0')]},
                {month:t('about.history.2021.5.month'), dep3:[t('about.history.2021.5.content.0')]},
                {month:t('about.history.2021.4.month'), dep3:[t('about.history.2021.4.content.0'),t('about.history.2021.4.content.1'),t('about.history.2021.4.content.2')]}
            ]},
        {year:2020, active:false, dep2:[
                {month:t('about.history.2020.12.month'), dep3:[t('about.history.2020.12.content.0')]},
                {month:t('about.history.2020.11.month'), dep3:[t('about.history.2020.11.content.0')]},
                {month:t('about.history.2020.10.month'), dep3:[t('about.history.2020.10.content.0')]},
                {month:t('about.history.2020.8.month'), dep3:[t('about.history.2020.8.content.0')]},
                {month:t('about.history.2020.7.month'), dep3:[t('about.history.2020.7.content.0')]},
                {month:t('about.history.2020.3.month'), dep3:[t('about.history.2020.3.content.0')]},
            ]},
        {year:2019, active:false, dep2:[
                {month:t('about.history.2019.11.month'), dep3:[t('about.history.2019.11.content.0')]},
                {month:t('about.history.2019.10.month'), dep3:[t('about.history.2019.10.content.0')]},
                {month:t('about.history.2019.6.month'), dep3:[t('about.history.2019.6.content.0')]},
                {month:t('about.history.2019.5.month'), dep3:[t('about.history.2019.5.content.0')]},
                {month:t('about.history.2019.2.month'), dep3:[t('about.history.2019.2.content.0')]},
            ]},
        {year:2018, active:false, dep2:[
                {month:t('about.history.2018.11.month'), dep3:[t('about.history.2018.11.content.0')]},
                {month:t('about.history.2018.8.month'), dep3:[t('about.history.2018.8.content.0')]},
                {month:t('about.history.2018.7.month'), dep3:[t('about.history.2018.7.content.0')]},
                {month:t('about.history.2018.1.month'), dep3:[t('about.history.2018.1.content.0')]},
            ]},
    ];
    const onScroll = () => {
        if(document.getElementsByClassName('item').length){
            for (let i = 0; i < document.getElementsByClassName('item').length; i++){
                if(i === 0){
                    document.getElementsByClassName('item')[i].classList.add('active')
                }else {

                    if(document.getElementsByClassName('item')[i].offsetTop < document.getElementById('wrap').scrollTop + window.innerHeight / 2){
                        document.getElementsByClassName('item')[i].classList.add('active')
                    }else {
                        document.getElementsByClassName('item')[i].classList.remove('active')
                    }
                }
            }
        }

        //console.log(document.getElementById('scrollArea').offsetTop)
    }
    useEffect(() => {
        document.getElementById('wrap').addEventListener('scroll',onScroll);
        // return () => {
        //     document.getElementById('wrap').removeEventListener('scroll', onScroll);
        //
        // }
    },[])
    return (
        <HistoryArea.Wrap>
            <HistoryArea.Contain>
                <HistoryArea.Title dangerouslySetInnerHTML={{__html:t('about.history.title')}}>

                </HistoryArea.Title>
                    <HistoryArea.List id={'scrollArea'} ref={scrollRef} >

                        {
                            list.map(({year,active,dep2},index) => {
                                return(
                                    <HistoryArea.Item key={year} className={active ? 'active item' : 'item'}>
                                        <HistoryArea.Year>{year}</HistoryArea.Year>
                                        <HistoryArea.Dep2>
                                            {dep2.map(({month,dep3}) => {
                                                    return(<li key={month}>
                                                            <strong>{month}</strong>
                                                            <div>
                                                                {dep3.map((i,index) =>{
                                                                        return <p key={index}>{i}</p>
                                                                })}
                                                            </div>
                                                        </li>)
                                            })}
                                        </HistoryArea.Dep2>
                                    </HistoryArea.Item>
                                )
                            })
                        }
                    </HistoryArea.List>
            </HistoryArea.Contain>
        </HistoryArea.Wrap>
    );
};

export default History;