import React from 'react';
import styled from "styled-components";
import {Btn} from "../Button";
import {MAIN_TEXT} from "../../utils/common";
import ImageCover from "../../assets/images/main/main02.svg"
import ImageCover2 from "../../assets/images/main/main02.png"
import {blankLinkNotion} from "../../utils/script";
import {useNavigate} from "react-router-dom";

const Page = {
    Wrap: styled.div`
      position:relative;width:100%;height:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;overflow:hidden;
      background:#fafafa;
      &:before{position:absolute;left:33%;top:-300px;content:'';width:1312px;height:1312px;background:url(${ImageCover})no-repeat center;z-index:1;animation:circle 40s infinite linear}
      @keyframes circle {
        0%{transform:rotate(0deg);}
        100%{transform:rotate(360deg);}
      }
      @media screen and (max-width:1024px){
        justify-content:center;
      }
      @media screen and (max-width:768px){
        &:before{left:-40%;background-image:url(${ImageCover2})}
      }
      @media screen and (max-width:375px){
        &:before{left:-127%;}
      }
    `,
}


const TextContainer = {
    TextGroup: styled.div`
      position:relative;z-index:100;text-align: center;display:flex;flex-direction:column;align-items: center;margin-bottom:208px;
      @media screen and (max-width:1024px){
        margin-bottom:100px;
      }
      @media screen and (max-width: 768px){
        margin-bottom:170px;
      }

    `,
    Text01: styled.p`
      position:relative;color:#000;font-size:92px;margin-bottom:40px;font-weight:600;
      @media screen and (max-width:1024px){
        font-size:60px;
      }
      @media screen and (max-width:768px){
        font-size:32px;line-height: 46px;margin-bottom:30px;word-break: keep-all;letter-spacing: 0;padding:0 30px;
      }
    `,
    Text02: styled.strong`
      position:relative;color:#000;font-size:36px;font-weight:400;
      @media screen and (max-width:1024px){
        font-size:28px;
      }

      @media screen and (max-width:768px){
        font-size:20px;line-height: 30px;padding:0 30px;word-break:keep-all;
      }
    `,
    Button: styled(Btn.Wrap)`
      width:290px;height:80px;position:relative;z-index:10;border:1px solid #1b1b1b;
      @media screen and (max-width:768px){
        width:208px;height:56px;
      }
    `,
    ButtonText:styled(Btn.Text)`
      font-size:16px;color:#1b1b1b;font-weight: 600;
      @media screen and (max-width:768px){
        font-size:14px;
      }
    `
}
const Section02 = () => {
    const navigate = useNavigate();
    const {list} = MAIN_TEXT();
    return (
        <Page.Wrap>
            <TextContainer.TextGroup>
                <TextContainer.Text01>{list.SECTION_02.text01}</TextContainer.Text01>
                <TextContainer.Text02>{list.SECTION_02.text02}</TextContainer.Text02>
            </TextContainer.TextGroup>
            <TextContainer.Button type={'button'} color={'white'} onClick={() => navigate('/intro/movements')}>
                <TextContainer.ButtonText color={'white'}>{list.SECTION_02.button}</TextContainer.ButtonText>
            </TextContainer.Button>
        </Page.Wrap>
    );
};

export default Section02;