import React from 'react';
import styled from "styled-components";

export const Btn = {
    Wrap:styled.button`
      display:flex;align-items:center;justify-content:center;
      width:${p => p.width ? p.width + 'px' : 'auto'};
      height:${p => p.height ? p.height + 'px' : '40px'};
      background:${p => p.color === 'white' ? '#fff' : '#fff'};
      border-radius:100px;
    `,
    Text:styled.span`
      font-size:${p => p.fontSize ? p.fontSize + 'px' : '16px'};
      color:${p => p.color === 'white' ? '#1b1b1b' : '#1b1b1b'};
    `
}

const Button = ({width,height,text,color,fontSize}) => {
    return (
        <Btn.Wrap width={width} height={height} type={'button'} color={color}>
            <Btn.Text color={color} fontSize={fontSize}>{text}</Btn.Text>
        </Btn.Wrap>
    );
};

export default Button;