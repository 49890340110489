import React from 'react';
import styled from "styled-components";
import noImage from "../assets/images/intro/img-no-image.png"
import {useTranslation} from "react-i18next";
import {Popup} from "./style";


const PopupCert = ({src, tag, desc, name, date, agency, onClose}) => {
    const {t} = useTranslation('translation');
    return (
        <>
            <Popup.Dimmed></Popup.Dimmed>
            <Popup.Wrap>
                <Popup.Head>
                    <Popup.TitleRow>
                        <Popup.Tag>{tag}</Popup.Tag>
                        <Popup.Title>{desc}</Popup.Title>
                    </Popup.TitleRow>
                    <Popup.Close type={'button'} onClick={onClose}><span className={'hidden'}>닫기</span></Popup.Close>
                </Popup.Head>
                <Popup.Content className={'cScroll'}>
                    <div className={'inner'}>
                        <Popup.LabelText>
                            <Popup.Label>{t('about.performance.popLabel.0')}</Popup.Label>
                            <Popup.Text>{name}</Popup.Text>
                        </Popup.LabelText>
                        <Popup.LabelText>
                            <Popup.Label>{t('about.performance.popLabel.1')}</Popup.Label>
                            <Popup.Text>{date}</Popup.Text>
                        </Popup.LabelText>
                        <Popup.LabelText>
                            <Popup.Label>{t('about.performance.popLabel.2')}</Popup.Label>
                            <Popup.Text>{tag}</Popup.Text>
                        </Popup.LabelText>
                        <Popup.LabelText>
                            <Popup.Label>{t('about.performance.popLabel.3')}</Popup.Label>
                            <Popup.Text>{agency}</Popup.Text>
                        </Popup.LabelText>
                        {src
                            ? <Popup.ImageBox>
                                <img src={src} alt={desc}/>
                            </Popup.ImageBox>
                            : <Popup.ImageBox noImage={true}>
                                <img src={noImage} alt={'이미지가 없습니다.'}/>
                            </Popup.ImageBox>
                        }
                    </div>
                </Popup.Content>
            </Popup.Wrap>
        </>
    );
};

export default PopupCert;