import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import i18n from "./lang/i18n";
import {RecoilRoot} from "recoil";

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient({
    defaultOptions:{
        queries:{
            refetchOnWindowFocus:false,
            refetchOnMount:false,
            retry:false,
        }
    }
})
root.render(
      <QueryClientProvider client={queryClient}>
          <RecoilRoot>
              <BrowserRouter>
                  <App />
              </BrowserRouter>
          </RecoilRoot>
      </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
