import React, {useEffect} from 'react';
import Main from "./pages/Main";
import "./common.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import {Route, Routes} from "react-router-dom";
import IntroRoute from "./router/IntroRoute";
import DtxRoute from "./router/DtxRoute";
import SolutionRoute from "./router/SolutionRoute";
import NewsRoute from "./router/NewsRoute";
import InquiryRoute from "./router/InquiryRoute";
import i18n from "i18next";
import PopupPrivacy from "./popup/PopupPrivacy";
import {useRecoilState} from "recoil";
import {privacyState, videoState} from "./store/common";
import PopupVideo from "./popup/PopupVideo";

const App = () => {
    const [video, setVideo] = useRecoilState(videoState);
    const [privacy, setPrivacy] = useRecoilState(privacyState)
    useEffect(() => {
        if(localStorage.getItem('MOVE_LANG') === 'en'){
            i18n.changeLanguage('en')
        }else {
            i18n.changeLanguage('ko')
        }
    },[])
    return (
        <>
            <Routes>
                <Route path={'/'} element={<Main/>}/>
                <Route path={'/intro/*'} element={<IntroRoute/>}/>
                <Route path={'/dtx/*'} element={<DtxRoute/>}/>
                <Route path={'/solution/*'} element={<SolutionRoute/>}/>
                <Route path={'/news/*'} element={<NewsRoute/>}/>
                <Route path={'/inquiry/*'} element={<InquiryRoute/>}/>
            </Routes>
            {
                privacy ? <PopupPrivacy/> : null
            }
            {
                video ? <PopupVideo/> : null
            }
        </>

    );
};

export default App;