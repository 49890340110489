import React, {useState} from 'react';

const UsePagination = () => {
    const [tableCont, setTableCont] = useState([]);     //페이징처리된 테이블데이터
    const [pageAble, setPageAble] = useState({
        totalPages: 0,
        totalElements: 0,
    });
    const renderPage = (data) => {
        if (data) {
            const {content, totalElements, totalPages} = data;
            setTableCont(content);
            setPageAble({
                totalPages: totalPages,
                totalElements: totalElements,
            });
        }
    }

    return [renderPage,tableCont,pageAble]
};

export default UsePagination;