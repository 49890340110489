import React, {useEffect, useState} from 'react';
import {NewsArea} from "../style";
import NewsCard from "../../components/NewsCard";
import {useQuery} from "@tanstack/react-query";
import {getNewsMedia} from "../../service/api/common";
import Pagination from "../../components/Pagination";
import usePagination from "../../hook/usePagination";
import {useTranslation} from "react-i18next";

const NewsMedia = () => {
    const {t} = useTranslation('translation')
    const [params, setParams] = useState({
        pageNum:1,
        size:5
    })

    const [renderPage,tableCont,pageAble] = usePagination();
    const newsMediaList = useQuery(['newsMedia',params.pageNum], () => getNewsMedia(params),{
        onSuccess:({datas}) => {

            renderPage(datas)
        },
        onError:(err) => {
            console.log(err.message)
        }
    })
    useEffect(() => {
        if(!tableCont.length){
            newsMediaList.refetch();
        }
    },[]);
    return (
        <>
            <NewsArea.Wrap>
                <NewsArea.Title>{t('news.title.0')}</NewsArea.Title>
            </NewsArea.Wrap>
            <NewsArea.NewsList>
                {
                    newsMediaList.isSuccess && tableCont.length ? tableCont.map(({contents,newsId,subject,reportDate,img,url}) => {
                        return (
                            <NewsCard key={newsId}
                                      title={contents}
                                      desc={subject}
                                      src={'https://movements.kr'+img.replace('t_','')}
                                      url={url}
                                      date={reportDate}
                            />
                        )
                    }): null
                }

                <Pagination totalPages={pageAble.totalPages} setPageInfo={setParams} pageInfo={params}/>
            </NewsArea.NewsList>
        </>

    );
};

export default NewsMedia;