import React, {useEffect, useState} from 'react';
import {NewsArea} from "../style";
import PromoCard from "../../components/PromoCard";
import {useQuery} from "@tanstack/react-query";
import {getPromotion} from "../../service/api/common";
import Tab2 from "../../components/Tab2";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const Promotion = () => {
    const {t, i18n} = useTranslation('translation')
    const promotionList = useQuery(['promotionList'], getPromotion,{
        onSuccess:({datas}) => {
            console.log(datas)
        }
    })
    const [list, setList] = useState([
        {name:t('news.tab.0'), active:true},
        {name:t('news.tab.1'), active:false}
    ]);
    useEffect(() => {
        setList([
            {name:t('news.tab.0'), active:list[0].active},
            {name:t('news.tab.1'), active:list[1].active},
        ])
    },[i18n.language])
    const onClick = (str) => {
        let newArr = [...list];
        for(let i = 0; i < newArr.length; i++){
            newArr[i].active = false;
        }
        newArr.filter(({name}) => name === str)[0].active = true
        setList(newArr)
    }
    return (
        <>
            <NewsArea.Wrap>
                <NewsArea.Title>{t('news.title.1')}</NewsArea.Title>
            </NewsArea.Wrap>
            <NewsArea.CardContain>
                <Tab2 list={list} onClick={onClick}/>
                <NewsArea.CardList>
                    {promotionList.isSuccess && list[0].active && promotionList.data.datas.kr.sort((a,b) => a.orders - b.orders).map(({fileId, fileName, img, orders, originalFileName}) => {
                        return (
                            <PromoCard key={orders} title={fileName} image={'https://movements.kr'+img} fileId={fileId} originalFileName={originalFileName}/>
                        )
                    })}

                    {promotionList.isSuccess && list[1].active && promotionList.data.datas.en.sort((a,b) => a.orders - b.orders).map(({fileId, fileName, img, orders, originalFileName}) => {
                        return (
                            <PromoCard key={orders} title={fileName} image={'https://movements.kr'+img} fileId={fileId} originalFileName={originalFileName}/>
                        )
                    })}
                </NewsArea.CardList>
            </NewsArea.CardContain>

        </>
    );
};

export default Promotion;