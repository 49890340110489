import axios from "axios";

export const request = axios.create({
    baseURL:'/',
    headers:{
        "Content-Type":"application/json",
    }
})


request.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        console.log(error);
        return Promise.reject(error);
    }
)

request.interceptors.response.use(
    response => {
        return response;
    },
    ({response}) => {
        if (response) {
            const {code, message} = response.data
            if (401 === code) {
                alert(message);
                window.location.href='/login';
            }
        }
        return Promise.reject(response);
    }
)