import styled from "styled-components";
import IcoSelect from "../assets/images/ico-select.png"
export const LabelArea = {
    Wrap:styled.div`
      display:flex;
      align-items:${p => p.align ? p.align : 'center'};
      label{
        ${p => p.align && 'margin-top:10px'};
      }
      @media screen and (max-width:768px){
        flex-direction:column;align-items:flex-start;
        label{
          margin-bottom:5px;
        }
      }
      
    `,
    Label:styled.label`
      display:flex;align-items:center;min-width: 146px;font-size:20px;color:#000;font-weight: 500;
      &.req{&:after{content:'*';margin-left:4px;color:#3100ff;font-size:24px;font-weight:500;}}
      @media screen and (max-width:1280px){
        font-size:18px;
        &.req{&:after{font-size:22px;}}
      }
      @media screen and (max-width:768px){
        font-size:16px;
        &.req{&:after{font-size:20px;}}
      }
      
    `,
    Input:styled.input`
      height:60px;flex:1;background:#fff;padding:0 24px;margin:0;box-sizing:border-box;border:1px solid #e5e5ec;border-radius:8px;color:#767676;
      font-size:20px;font-weight:400;
      @media screen and (max-width: 1024px){
        height:40px;font-size:18px;
      }
      @media screen and (max-width: 768px){
        width:100%;flex:none;font-size:16px;
      }
    `,
    Select:styled.select`
      position:relative;height:60px;flex:1;background:#fff url(${IcoSelect})no-repeat right 20px center;background-size:14px 10px;
      appearance:none;padding:0 44px 0 24px;margin:0;box-sizing:border-box;border:1px solid #e5e5ec;border-radius:8px;color:#767676;font-size:20px;font-weight:400;
      @media screen and (max-width: 1024px){
        height:40px;font-size:18px;
      }
      @media screen and (max-width: 768px){
        width:100%;flex:none;font-size:16px;
      }
    `,
    Textarea:styled.textarea`
      height:${p => p.height ? p.height + 'px' : '320px'};resize:none;line-height: 34px;
      flex:1;background:#fff;padding:16px 24px;margin:0;box-sizing:border-box;border:1px solid #e5e5ec;border-radius:8px;color:#767676;font-size:20px;font-weight:400;
      @media screen and (max-width:1280px){
        font-size:18px;
      }
      @media screen and (max-width:768px){
        font-size:16px;width:100%;
      }
    `,
    Row:styled.div`
      position:relative;display:flex;align-items:center;
      @media screen and (max-width:440px){
        flex-direction: column;align-items:flex-start;
        >div:not(:last-child){margin-bottom:5px;}
      }
    `

}