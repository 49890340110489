import partner01 from "../assets/images/intro/logo-partner-01.png"
import partner02 from "../assets/images/intro/logo-partner-02.png"
import partner03 from "../assets/images/intro/logo-partner-03.png"
import partner04 from "../assets/images/intro/logo-partner-04.png"
import partner05 from "../assets/images/intro/logo-partner-05.png"
import partner06 from "../assets/images/intro/logo-partner-06.png"
import partner07 from "../assets/images/intro/logo-partner-07.png"
import partner08 from "../assets/images/intro/logo-partner-08.png"
import partner09 from "../assets/images/intro/logo-partner-09.png"
import partner10 from "../assets/images/intro/logo-partner-10.png"
import partner11 from "../assets/images/intro/logo-partner-11.png"
import partner12 from "../assets/images/intro/logo-partner-12.png"
import partner13 from "../assets/images/intro/logo-partner-13.png"
import partner14 from "../assets/images/intro/logo-partner-14.png"
import partner15 from "../assets/images/intro/logo-partner-15.png"
import partner16 from "../assets/images/intro/logo-partner-16.png"
import partner17 from "../assets/images/intro/logo-partner-17.png"
import partner18 from "../assets/images/intro/logo-partner-18.png"
import partner19 from "../assets/images/intro/logo-partner-19.png"
import partner20 from "../assets/images/intro/logo-partner-20.png"
import sol01 from "../assets/images/main/img-solution-01.png"
import sol02 from "../assets/images/main/img-solution-02.png"
import sol03 from "../assets/images/main/img-solution-03.png"
import sol04 from "../assets/images/main/img-solution-04.png"
import people01 from "../assets/images/main/img-people-01.png"
import people02 from "../assets/images/main/img-people-02.png"
import people03 from "../assets/images/main/img-people-03.png"
import {useTranslation} from "react-i18next";


export const MENU_LIST = () => {
    const {t} = useTranslation('translation')
    const menuList = [
            {name:t('menuList.0.name'), link:'/', active:false, dep2:[
                    {name:t('menuList.0.dep2.0.name'), tabName:t('menuList.0.dep2.0.tabName'), link:'/intro/movements'},
                    {name:t('menuList.0.dep2.1.name'), tabName:t('menuList.0.dep2.1.tabName'), link:'/intro/history'},
                    {name:t('menuList.0.dep2.2.name'), tabName:t('menuList.0.dep2.2.tabName'), link:'/intro/partners'},
                    {name:t('menuList.0.dep2.3.name'), tabName:t('menuList.0.dep2.3.tabName'), link:'/intro/performance'},
                    {name:t('menuList.0.dep2.4.name'), tabName:t('menuList.0.dep2.4.tabName'), link:'/intro/talent'},
                    {name:t('menuList.0.dep2.5.name'), tabName:t('menuList.0.dep2.5.tabName'), link:'/intro/location'},
                ]},
            {name:t('menuList.1.name'), link:'/', active:false, dep2:[
                    {name:t('menuList.1.dep2.0.name'), tabName:t('menuList.1.dep2.0.tabName'), link: '/dtx/dtx'},
                    {name:t('menuList.1.dep2.1.name'), tabName:t('menuList.1.dep2.1.tabName'), link: '/dtx/dtx-ar'},
                    {name:t('menuList.1.dep2.2.name'), tabName:t('menuList.1.dep2.2.tabName'), link: '/dtx/metaverse'},
                ]},
            {name:t('menuList.2.name'), link:'/', active:false, dep2:[
                    {name:t('menuList.2.dep2.0.name'), tabName:t('menuList.2.dep2.0.tabName'), link: '/solution/cmls'},
                    {name:t('menuList.2.dep2.1.name'), tabName:t('menuList.2.dep2.1.tabName'), link: '/solution/sms'},
                    {name:t('menuList.2.dep2.2.name'), tabName:t('menuList.2.dep2.2.tabName'), link: '/solution/pbd'},
                    {name:t('menuList.2.dep2.3.name'), tabName:t('menuList.2.dep2.3.tabName'), link: '/solution/dcm'},
                    {name:t('menuList.2.dep2.4.name'), tabName:t('menuList.2.dep2.4.tabName'), link: '/solution/soil'},
                ]},
            {name:t('menuList.3.name'), link:'/', active:false, dep2:[
                    {name:t('menuList.3.dep2.0.name'), tabName:t('menuList.3.dep2.0.tabName'),link: '/news/news-media'},
                    {name:t('menuList.3.dep2.1.name'), tabName:t('menuList.3.dep2.1.tabName'),link: '/news/promotion'},
                ]},
            {name:t('menuList.4.name'), link:'/', active:false, dep2:[
                    {name:t('menuList.4.dep2.0.name'), tabName:t('menuList.4.dep2.0.tabName'), link:'/inquiry/online'}
                ]},
        ]

    return [menuList]
}



export const MAIN_TEXT = () => {
    const {t} = useTranslation('translation')
    const list = {
        SECTION_01:{
            text01: t('main.section01.text01'),
            text02: t('main.section01.text02'),
            text03: t('main.section01.text03'),
            button: t('main.section01.button')
        },
        SECTION_02:{
            text01: t('main.section02.text01'),
            text02: t('main.section02.text02'),
            button:'View More',
        },
        SECTION_03:{
            item01:{
                tag:t('main.section03.tag'),
                info:t('main.section03.info'),
                title:t('main.section03.item01.title'),
                desc:t('main.section03.item01.desc'),
                src:sol01,
                color:'linear-gradient(to right top,rgba(241,185,163),rgba(250,227,203))'
            },
            item02:{
                tag:t('main.section03.tag'),
                info:t('main.section03.info'),
                title:t('main.section03.item02.title'),
                desc:t('main.section03.item02.desc'),
                src:sol02,
                color:'linear-gradient(to right top,rgba(142,123,223),rgba(129,205,248))'
            },
            item03:{
                tag:t('main.section03.tag'),
                info:t('main.section03.info'),
                title:t('main.section03.item03.title'),
                desc:t('main.section03.item03.desc'),
                src:sol04,
                color:'linear-gradient(to right top,rgba(181,253,112),rgba(252,237,151))'
            },
            // item04:{
            //     tag:t('main.section03.tag'),
            //     info:t('main.section03.info'),
            //     title:t('main.section03.item04.title'),
            //     desc:t('main.section03.item04.desc'),
            //     src:sol04,
            //     color:'linear-gradient(to right top,rgba(181,253,112),rgba(252,237,151))'
            // },
            item05:{
                tag:t('main.section03.tag'),
                info:t('main.section03.info'),
                title:t('main.section03.item05.title'),
                desc:t('main.section03.item05.desc'),
                src:sol03,
                color:'linear-gradient(to right top,rgba(206,201,252),rgba(255,211,211))'
            }
        },
        SECTION_04:{
            item01:{
                tag:t('main.section04.tag'),
                info:t('main.section04.info'),
                title:t('main.section04.item01.title'),
                desc:t('main.section04.item01.desc'),
                src:people01,
                color:'linear-gradient(to right top,rgba(75,57,190),rgba(190,186,250))'
            },
            item02:{
                tag:t('main.section04.tag'),
                info:t('main.section04.info'),
                title:t('main.section04.item02.title'),
                desc:t('main.section04.item02.desc'),
                src:people02,
                color:'linear-gradient(to right top,rgba(254,170,194),rgba(250,208,125))'
            },
            item03:{
                tag:t('main.section04.tag'),
                info:t('main.section04.info'),
                title:t('main.section04.item03.title'),
                desc:t('main.section04.item03.desc'),
                src:people03,
                color:'linear-gradient(to right top,rgba(255,144,175),rgba(255,206,222))'
            }
        }
    }
    return {list}
}


export const INTRO_TEXT = {
    VISUAL:{
        TITLE:'ABOUT',
        DESC:'혁신을 주도하는 스마트 건설산업의 대표, 무브먼츠를 소개합니다.'
    },
    PARTNER:[
        {name:'국토교통부',src:partner01},
        {name:'환경부',src:partner02},
        {name:'K water',src:partner03},
        {name:'KICT 한국건설기술연구원',src:partner04},
        {name:'고양시',src:partner05},
        {name:'LH 한국토지주택공사',src:partner06},
        {name:'LX 한국국토정보공사',src:partner07},
        {name:'IH 인천도시공사',src:partner08},
        {name:'한국지역난방공사',src:partner09},
        {name:'한국전력공사',src:partner10},
        {name:'BPA 부산항만공사',src:partner11},
        {name:'강원도시가스 SK E&S',src:partner12},
        {name:'현대건설',src:partner13},
        {name:'SK 하이닉스',src:partner14},
        {name:'ANGELSWING',src:partner15},
        {name:'삼성전자',src:partner16},
        {name:'금광기업',src:partner17},
        {name:'코오롱글로벌',src:partner18},
        {name:'태영건설',src:partner19},
        {name:'Leica Geosystems',src:partner20},
    ]
}

export const DTX_TEXT = {
    VISUAL:{
        TITLE:'DTX',
        DESC:'다양한 요인에 의하여 생산성과 안전성이 떨어지는 기존 건설 산업의 문제점을 해결 하기 위하여<br/>정보 통신기술(ICT) 및 인공지능, 증강현실 기술을 시공현장에 접목하여 스마트한 건설 현장 관리를 가능하게 합니다.'
    },
    SOLUTION01:{
        item01:{
            tag:'Metaverse',
            info:'가상 시공 검토',
            desc:'<span>- 지하, 지상시설물의 시공 데이터를 3D 공간에서 시각화</span><span>- 시각화된 시공 데이터를 기반으로 가상 시공 기능 제공</span><span>- 실제 시공 데이터와 가상 시공 데이터의 간섭 여부 확인 기능 제공</span><span>- 기존 시설물 파손 ZERO를 위한 시공 현장에서 가상 시공 AR 제공</span>'
        }
    },
    SOLUTION02:{
        item01:{
            tag:'Metaverse',
            info:'지상 건축물 관리 서비스',
            desc:'<span>- 아파트, 빌딩, 플렌트 등 지상 건출물을 3D 공간에서 시각화</span><span>- 지상 건축물 시공관리 및 시공 현장 작업자 위치 추적 관리 제공</span><span>- Smart Home Iot 연동을 통한 편리한 생활 환경 제공</span><span>- IoT, AI 기술을 이용하여 지능화된 건출물 통합 과제</span><span>- 에너지 효율 관리/시설물 이력 관리</span>'
        }
    },
}


export const SOLUTION_TEXT = {
    VISUAL:{
        TITLE:'솔루션',
        DESC:'실제 건설 현장과 동일한 디지털 복제본을 증강현실, 가상현실기술로 3차원 가상세계에 표현하여<br/> 실제세계와 가상세계를 실시간으로 통합 하고 현실 세계에서 일어날 수 있는 일들을 예측합니다.'
    },
}

export const NEWS_TEXT = {
    VISUAL:{
        TITLE:'소식',
        DESC:'혁신을 주도하는 스마트 건설산업의 대표, 무브먼츠를 소개합니다.'
    }
}

export const INQUIRY_TEXT = {
    VISUAL:{
        TITLE:'도입 문의',
    }
}