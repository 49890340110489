import React, {useState} from 'react';
import styled from "styled-components";
import IcoDown from "../assets/images/news/ico-down.svg";
import IcoDownHover from "../assets/images/news/ico-down-hover.svg";
import {useQuery} from "@tanstack/react-query";
import {getFileDown} from "../service/api/common";

const PromoCardArea = {
    Wrap:styled.div`
      width:calc(25% - 34.5px);margin-top:150px;margin-right:46px;
      &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4){margin-top:0;}
      &:nth-child(4n){margin-right:0;}
      @media screen and (max-width:1600px){
        width:calc(33.3% - 31px);
        &:nth-child(4n){margin-right:46px;}
        &:nth-child(4){margin-top:150px;}
        &:nth-child(3n){margin-right:0;}

      }
      @media screen and (max-width:1280px){
        margin-right:30px;
        &:nth-child(4n){margin-right:30px;}
      }
      @media screen and (max-width:1024px){
        margin-top:80px;width:calc(50% - 15px);
        &:nth-child(3n){margin-right:30px;}
        &:nth-child(3), &:nth-child(4){margin-top:80px;}
        &:nth-child(2n){margin-right:0;}
      }
      @media screen and (max-width:768px){
        width: 100%;margin-right:0;margin-top:60px;
        &:nth-child(3n){margin-right:0;}
        &:nth-child(2), &:nth-child(3), &:nth-child(4){margin-top:60px;}

      }
    `,
    Image:styled.div`
      padding:10px;border:1px solid #d8d8d8;border-radius:12px;height:478px;text-align: center;display:flex;align-items:center;
      img{display:block;width:100%;margin:0 auto;}
      @media screen and (max-width:1280px){
        height:360px;
      }

      @media screen and (max-width:1024px){
      }
      @media screen and (max-width:768px){
        height:auto;
        img{width: auto; height:100%;max-width:100%;}
      }
    `,
    Title:styled.strong`
      color:#1b1b1b;font-size:24px;font-weight:600;overflow:hidden;text-overflow: ellipsis;white-space:nowrap;flex:1;text-align:left;
      @media screen and (max-width:1280px){
        font-size:22px;
      }
      @media screen and (max-width:1024px){
        font-size:21px;
      }
      @media screen and (max-width:768px){
        font-size:20px;
      }
    `,
    Down:styled.button`
      position:relative;width:48px;height: 48px;border-radius:50%;border:1px solid #3100ff; 
      background-image:${p => p.loading ? "none" : `url(${IcoDown})`};transition:.4s;
      background-position:center;background-repeat:no-repeat;

      @keyframes rotation {
        0%{transform:translate(-50%, -50%) rotate(0)}
        100%{transform:translate(-50%, -50%) rotate(360deg)}
      }
      ${p => p.loading && `
          &:before{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);width:20px;height:20px;border-radius:50%;content: '';display:block;
            border:3px solid #3100ff; border-right:3px solid transparent;animation:2s rotation infinite linear;transition:.4s;
          }
      `}
      
      &:hover{
        background-color:#3100ff;
        background-image: ${p => p.loading ? "none" : `url(${IcoDownHover})`};
        ${p => p.loading && `
          &:before{
            border:3px solid #fff; border-right:3px solid transparent;
          }
        `}
      }
      @media screen and (max-width:1280px){
        width:40px;height:40px;background-size:22px 22px;
      }
      @media screen and (max-width:1024px){
        width:36px;height:36px;background-size:20px 20px;
      }
    `,
    TitleArea:styled.div`
      display:flex;align-items:center;justify-content:space-between;margin-top:22px;
      @media screen and (max-width:1280px){
        margin-top:20px;
      }
      @media screen and (max-width:1024px){
        margin-top:18px;
      }
      @media screen and (max-width:768px){
        margin-top:16px;
      }
    `
}

const PromoCard = ({image, title, fileId, originalFileName}) => {

    const [loading, setLoading] = useState(false)
    const onClick = (id) => {
        setLoading(true)
        getFileDown(id).then(({data}) => {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/*' }))
            a.download = originalFileName.normalize('NFC');
            a.click()
            setLoading(false)
        }).catch(() => {
            alert('다운로드에 실패했습니다.')
            setLoading(false)
        })
    }
    return (
        <PromoCardArea.Wrap>
            <PromoCardArea.Image>
                <img src={image ? image : require('../assets/images/news/img-promo-no-image.png')} alt={title}/>
            </PromoCardArea.Image>
            <PromoCardArea.TitleArea>
                <PromoCardArea.Title>{title}</PromoCardArea.Title>
                <PromoCardArea.Down loading={loading} type={'button'} onClick={()=> onClick(fileId)} disabled={loading}><span className={'hidden'} >다운로드</span></PromoCardArea.Down>
            </PromoCardArea.TitleArea>
        </PromoCardArea.Wrap>
    );
};

export default PromoCard;