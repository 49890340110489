import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {MENU_LIST} from "../utils/common";
import Logo from "../assets/images/logo.png"
import Logo2 from "../assets/images/logo2.png"
import {useNavigate} from "react-router-dom";
import i18n from "i18next";
import LanguageCombo from "./LanguageCombo";

const HeaderAreaDefault = {
    Wrap:styled.header`
      position:absolute;left:0;top:0;z-index:100;width:100%;height:110px;transition:.2s;
      &.active{background:rgba(0,0,0,.7);border-bottom: 1px solid rgba(216,216,216,.27)}
      &:hover{background:rgba(0,0,0,.7) }
      @media screen and (max-width: 1024px){
        position:fixed;height:68px;
        &.active{background:none;border-bottom:0;}
        &.scroll{background:rgba(0,0,0,.7)!important;}
        &:hover{background:none}
      }
      
    `,
    Contain:styled.div`
      width:1530px;display:flex;align-items:center;margin:0 auto;;
      @media screen and (max-width: 1600px){
        width: auto;padding:0 100px;
      }
      @media screen and (max-width:1280px){
        padding:0 40px
      }
      @media screen and (max-width:1024px){
        justify-content: space-between;
      }
      @media screen and (max-width:768px){
        padding:0 30px
      }
    `,
    Logo:styled.h1`
      width:210px;height: 32px;background: url(${Logo})no-repeat center; background-size:cover;cursor:pointer;transition:.4s;
      @media screen and (max-width: 1024px){
        width:130.7px;height: 19.8px;margin-top:22px;
      }
    `,
    MenuBtn:styled.button`
      position:absolute;right:30px;top:22px;z-index:10002;display:none;flex-direction:column;justify-content:center;align-items:center;width:24px;height:24px;
      transition:.4s;
      span{position:absolute;left:50%;top:50%;transform: translate(-50%,-50%);width:20px;height:1px;background:#fff;transition:.4s;
        &:first-child{margin-top:-9px}
        &:nth-child(2), &:nth-child(3){transform-origin:center center;}
        &:last-child{margin-top:9px}
      }
      @media screen and (max-width:1024px){
        display:flex;
      }
    `,
    Menu:styled.ul`
      position:relative;display:flex;margin-left: auto;
      @media screen and (max-width:1024px){
        position: fixed;right:-210px;top:0;z-index:10001;width:210px;height:100%;padding-top:88px;background:#fff;
        flex-direction: column;visibility:hidden;opacity: 0;transition:.4s;
      }
    `,
    Dimmed:styled.div`
      display: none;
      @media screen and (max-width:1024px){
        position:fixed;left:0;top:0;display:block;width:100%;height:100%;z-index:10000;background:rgba(0,0,0,.6);transition:.4s;opacity:0;visibility:hidden;
      }
    `,
    MenuItem:styled.li`
      cursor: pointer;min-width:37px;margin-right:40px;
      &:not(:last-child){margin-right:70px;}
      >span{line-height:110px;display:block;font-size:20px;color:#c3c3c3;font-weight:400;}
      &:hover{
        >span{color:#fff;font-weight:700;}
      }
      @media screen and (max-width:1024px){
        width:100%;padding:0 32px 0 28px;
        >span{display:flex;align-items:center;justify-content:space-between;line-height:52px;color:#1b1b1b;font-size:18px;
          &:after{content:'';width: 8px;height:8px;transform:translateY(-5px) rotate(-45deg);border-left:1px solid #1b1b1b;border-bottom:1px solid #1b1b1b;}
        }
        &:hover{
          >span{color:#1b1b1b;font-weight:400;}
        }
      }
    `,
    Dep2:styled.ul`
      position:absolute;left:0;top:100%;padding-top:34px;visibility:hidden;opacity:0;padding-bottom:20px;transition:.2s;height:400px;
      &:before{content:'';display:block;visibility:hidden;opacity:0;position:absolute;left:100%;top:0;width:calc(1920px - 100%);height:400px;transition:.2s;background:rgba(0,0,0,.7)}
      &:after{content:'';display:block;visibility:hidden;opacity:0;position:absolute;right:100%;top:0;width:calc(1920px - 100%);height:400px;transition:.2s;background:rgba(0,0,0,.7)}
      @media screen and (max-width:1280px){
        >span{margin:0 30px;}
      }
      @media screen and (max-width:1024px){
        position:static;padding:0;display:none;height:auto;
        &:before{display:none;}
        &:after{display:none;}
      }
    `,
    Dep2Item:styled.li`
      position:relative;z-index:10;font-size:20px;color:#c3c3c3;width:auto;cursor: pointer;font-weight:400;
      &:not(:last-child){margin-bottom:30px;}
      &:hover{color:#fff;font-weight:600;}
      `
}

const HeaderArea = {
    ...HeaderAreaDefault,
    Wrap:styled(HeaderAreaDefault.Wrap)`
      @media screen and (max-width: 1024px){
        &.s2, &.s3, &.s4{
          ${HeaderAreaDefault.Logo}{background-image: url(${Logo2})}
          ${HeaderAreaDefault.MenuBtn}{
            span{background:#1b1b1b;}
          }
        }
      }
    `,
    Contain:styled(HeaderAreaDefault.Contain)`
      @media screen and (max-width:1024px){
        &.show{
          ${HeaderAreaDefault.MenuBtn}{
            span{background: #3100ff;
              &:nth-child(2){transform:translate(-50%,-50%) rotate(45deg);}
              &:nth-child(3){transform:translate(-50%,-50%) rotate(-45deg);}
              &:first-child{margin-top:-13px;opacity:0;}
              &:last-child{margin-top:13px;opacity:0;}
            }
          }
          ${HeaderAreaDefault.Menu}{right:0;visibility:visible;opacity: 1}
          ${HeaderAreaDefault.Dimmed}{visibility:visible;opacity: 1}
        }
      }
    `,
    MenuItem:styled(HeaderAreaDefault.MenuItem)`
      &:hover{
        ${HeaderAreaDefault.Dep2}{visibility:visible;opacity:1;background:rgba(0,0,0,.7);transition:.2s;
          &:before{visibility:visible;opacity:1;}
          &:after{visibility:visible;opacity:1;}
        }
      }
      @media screen and (max-width:1024px){
        &.active{
          >span{
            &:after{transform:translateY(0) rotate(135deg);}
          }
          ${HeaderAreaDefault.Dep2}{display:block;background:none;visibility:visible;opacity: 1;margin:4px 0 20px;}
          ${HeaderAreaDefault.Dep2Item}{display:flex;align-items:center;margin-left:8px;color:#8d8d8d;font-size:16px;min-height:36px;line-height:24px;margin-bottom:0;}
          
        }
      }
    `,
}

/* 공통 헤더*/
const Header = ({className}) => {

    const [menuList] = MENU_LIST();
    const navigate = useNavigate();
    const [focusing, setFocusing] = useState(false);
    const [show, setShow] = useState(false);
    const [menu, setMenu] = useState(menuList)
    const [size, setSize] = useState(window.innerWidth);


    const onClick = () => {
        setShow(!show);
        !show && sTop === 0 && setFocusing(false)
    }
    const [sTop, setSTop] = useState(0);
    const onDepOpen = (str) => {
        let newArr = [...menuList];
        if(newArr.filter(({name}) => name === str)[0].active){
            newArr.filter(({name}) => name === str)[0].active = false
        }else{
            for(let i = 0; i < newArr.length; i++){
                newArr[i].active = false
            }
            newArr.filter(({name}) => name === str)[0].active = true
        }
        setMenu(newArr)
    }
    const onFocus = () => {
        setFocusing(true);
    }
    const onFocusOut = () => {
        setFocusing(false);
    }
    const onLink = (link) => {
        navigate(link)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        setShow(false)
    }

    const onTop = () => {
        document.getElementById('wrap').scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    const onScroll = () => {
        setSTop(document.getElementById('wrap').scrollTop)
    }
    const onResize = () => {
        setSize(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize',onResize);
        return () => window.removeEventListener('resize', onResize);
    })

    useEffect(() => {
        document.getElementById('wrap') && document.getElementById('wrap').addEventListener('scroll',onScroll);
        return () => {
            document.getElementById('wrap') && document.getElementById('wrap').removeEventListener('scroll', onScroll);

        }
    })
    return (
        <>
            <HeaderArea.Wrap
                className={
                            focusing && className && sTop ? `active ${className} scroll`
                            : focusing && className && !sTop ? `active ${className}`
                            : focusing && !className && sTop ? 'active scroll'
                            : focusing && !className && !sTop ? 'active'
                            : !focusing && className && sTop ? `${className} scroll`
                            : !focusing && className && !sTop ? `${className}`
                            : !focusing && !className && sTop ? `scroll`
                            : undefined
                } onMouseEnter={onFocus} onMouseLeave={onFocusOut}>
                <HeaderArea.Contain className={show ? 'show' : undefined} onDoubleClick={onTop}>
                    <HeaderArea.Logo onClick={() => navigate('/')}><span className={'hidden'}>Movements</span></HeaderArea.Logo>
                    {/* 메뉴 */}
                    <HeaderArea.Menu >
                        {
                            menuList.map(({name, link, dep2, active},index) => {
                                return (
                                    <HeaderArea.MenuItem key={index} className={menu[index].active ? 'active' : undefined} onClick={() => onDepOpen(name)}>
                                        <span>{name}</span>
                                        {
                                            dep2
                                                ? <HeaderArea.Dep2>
                                                    {
                                                        dep2.map(({name,link,tabName},index) => {
                                                            return (
                                                                <HeaderArea.Dep2Item key={index} onClick={() => onLink(link)}>{size > 1024 ? name : tabName}</HeaderArea.Dep2Item>
                                                            )
                                                        })
                                                    }
                                                </HeaderArea.Dep2>
                                                : null
                                        }
                                    </HeaderArea.MenuItem>
                                );
                            })
                        }

                    </HeaderArea.Menu>
                    <LanguageCombo className={className}/>
                    {/* //메뉴 */}
                    <HeaderArea.MenuBtn type={'button'} onClick={onClick}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </HeaderArea.MenuBtn>
                    <HeaderArea.Dimmed onClick={onClick}></HeaderArea.Dimmed>
                </HeaderArea.Contain>
            </HeaderArea.Wrap>

        </>
    );
};

export default Header;