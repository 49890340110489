import React from 'react';
import styled from "styled-components";
import IcoTop from "../assets/images/main/ico-top.png"
import Logo from "../assets/images/logo2.png"
import {useNavigate} from "react-router-dom";
import {useRecoilState} from "recoil";
import {privacyState} from "../store/common";
import {useTranslation} from "react-i18next";
import {blankLinkNotion} from "../utils/script";
const FooterArea = {
    Wrap: styled.footer`
      width:100%;
      @media screen and (max-width:1024px){
        margin-top:70px;
      }
    `,
    Inner:styled.div`
      max-width:1530px;margin:0 auto;
      @media screen and (max-width:1600px){
        padding:0 100px;
      }
      @media screen and (max-width:1280px){
        padding:0 40px;
      }
      @media screen and (max-width:768px){
        padding:0 25px;
      }
    `,
    Row:styled.div`
      border-top:1px solid #d8d8d8;
    `,
    TermRow:styled.div`
      position:relative;display:flex;justify-content:space-between;align-items:center;
    `,
    TermCont:styled.ul`
      display:flex;align-items:center;height:100px;
      @media screen and (max-width:1024px){
        flex:1;height: 60px;
      }
      @media screen and (max-width:1024px){
        height: 46px;
      }
    `,
    TermItem:styled.li`
      display:flex;align-items:center;color:#000;font-size:20px;font-weight:400;cursor: pointer;
      &:not(:last-child){
        &:after{content:'';width:1px;height:15px;background:#d3d3d3;margin:0 20px;}
      }
      @media screen and (max-width:1024px){
        position:relative;flex:1;justify-content: center;text-align: center;font-size:18px;
        &:not(:last-child):after{position:absolute;margin:0;left:100%;}
      }
      @media screen and (max-width: 768px){
        font-size:12px;
      }
    `,
    InfoInner:styled.div`
      padding:60px 0;
      @media screen and (max-width: 768px){
        padding:40px 0;
      }
    `,
    BtnTop:styled.button`
      width:56px;height:56px;font-size:16px;display:flex;flex-direction:column;align-items:center;justify-content:center;font-weight:600;color:#767676;
      &:before{content:'';width:36px;height:36px;background:url(${IcoTop})no-repeat center;}
      @media screen and (max-width:1024px){
        position:absolute;right:0;top:-73px;z-index:10;
      }
      @media screen and (max-width:768px){
        font-size:12px;
        &:before{width:24px;height:24px;background-size:80%}
      }
    `,
    Logo:styled.h1`
      width:182px;height:28px;background:url(${Logo})no-repeat center;margin-bottom:29px;background-size: cover;
      @media screen and (max-width: 768px){
        margin:0 auto 18px;width:144px;height:22px;
      }
    `,
    InfoRow:styled.div`
      display:flex;align-items:center;
      &:not(:last-child){margin-bottom:10px;}
      &:nth-child(3){
        flex-wrap: wrap;
      }

      @media screen and (max-width: 1600px){
        &:nth-child(3){
          flex-wrap: wrap;
          >div{
            width:50%;
            &:after{display:none;}
            &:nth-child(3), &:nth-child(4){margin-top:10px;}
          }
        }
      }
      @media screen and (max-width: 1024px){
        &:nth-child(2),&:nth-child(3){
          flex-wrap: wrap;
          >div{width:100%;margin-bottom:10px;
            &:after{display: none;}
            &:nth-child(3), &:nth-child(4){margin-top:0;}
          }
        }
      }
      @media screen and (max-width: 768px){
        margin-bottom:0!important;
        &:nth-child(2){
          >div{width:50%;justify-content: flex-start!important;}
        }
        &:nth-child(3){
          >div{justify-content:flex-start!important;}
        }
        >div{letter-spacing:-1px;
          &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4){justify-content: center;margin-bottom:7px;}
          
        }
      }
    `,
    InfoItem:styled.div`
      display:flex;align-items:center;font-size:14px;color:#1b1b1b;font-weight:400;
      &:not(:last-child){
        &:after{content:'';width:1px;height:10px;background:#d3d3d3;margin:0 20px;}
      }
      >span{color:#767676;font-weight:400;}
      @media screen and (max-width:768px){
        font-size:12px;word-break:keep-all;align-items: flex-start;
        >span{flex:1;}
      }
    `

}

const Footer = ({type, top}) => {
    const {t} = useTranslation('translation');
    const menu = [
        {name:t('common.footer.0'),link:'/',popup:'privacy'},
        {name:t('common.footer.1'),link:'/inquiry/online'},
        //{name:t('common.footer.2'),link:'/'},
        {name:t('common.footer.3'),link:'/', newPage:'https://www.notion.so/Movements-06e7e0614df64f41b2a4e073652c9b3e'}
    ]
    const copyRight = [
        {label:t('common.copyright.0.label'), text:t('common.copyright.0.text')},
        {label:t('common.copyright.1.label'), text:t('common.copyright.1.text')},
        {label:t('common.copyright.2.label'), text:t('common.copyright.2.text')},
        {label:t('common.copyright.3.label'), text:t('common.copyright.3.text')},
        {label:t('common.copyright.4.label'), text:t('common.copyright.4.text')},
        {label:t('common.copyright.5.label'), text:t('common.copyright.5.text')},
        {label:t('common.copyright.6.label'), text:t('common.copyright.6.text')},
        {label:t('common.copyright.7.label'), text:t('common.copyright.7.text')}
    ]
    const [privacy, setPrivacy] = useRecoilState(privacyState)
    const navigate = useNavigate();
    const onTop = () => {
        if(type === 'fullpage'){
            top.moveTo('s1')
        }else{
            document.getElementById('wrap').scrollTo({
                top:0,
                behavior:'smooth'
            })
        }
    }
    const onPrivacy = () => {
        setPrivacy(!privacy)
    }
    const onLink = (link, popup, newPage) => {
        if (link !== '/'){
            navigate(link)
        }else if(popup === 'privacy'){
            onPrivacy()
        }else if(newPage){
            blankLinkNotion();
        }
    }
    return (
        <>
            <FooterArea.Wrap>
                <FooterArea.Row>
                    <FooterArea.Inner>
                        <FooterArea.TermRow>
                            <FooterArea.TermCont>
                                {
                                    menu.map(({name,link, popup, newPage},index) => {
                                        return (
                                            <FooterArea.TermItem key={index} onClick={() => onLink(link, popup, newPage)}>{name}</FooterArea.TermItem>
                                        )
                                    })
                                }

                            </FooterArea.TermCont>
                            <FooterArea.BtnTop type={'button'} onClick={onTop}>TOP</FooterArea.BtnTop>
                        </FooterArea.TermRow>
                    </FooterArea.Inner>
                </FooterArea.Row>
                <FooterArea.Row>
                    <FooterArea.Inner>
                        <FooterArea.InfoInner>
                            <FooterArea.Logo><span className={'hidden'}>Movements</span></FooterArea.Logo>
                            <FooterArea.InfoRow>
                                {
                                    copyRight.map((i,index) => {
                                        return(
                                            index < 4
                                                ? <FooterArea.InfoItem key={index}>{i.label} : {i.text}</FooterArea.InfoItem>
                                                : null
                                        )
                                    })
                                }
                            </FooterArea.InfoRow>
                            <FooterArea.InfoRow>
                                {
                                    copyRight.map((i,index) => {
                                        return(
                                            index >= 4
                                                ? <FooterArea.InfoItem key={index}>{i.label} :&nbsp;<span> {i.text}</span></FooterArea.InfoItem>
                                                : null
                                        )
                                    })
                                }
                            </FooterArea.InfoRow>
                            <FooterArea.InfoRow>
                                <FooterArea.InfoItem><span>COPYRIGHT©2022MOVEMENTS ALL RIGHTS RESERVED.</span></FooterArea.InfoItem>
                            </FooterArea.InfoRow>
                        </FooterArea.InfoInner>
                    </FooterArea.Inner>
                </FooterArea.Row>
            </FooterArea.Wrap>
        </>
    );
};

export default Footer;