import React from 'react';
import {Popup} from "./style";
import {useTranslation} from "react-i18next";
import {useRecoilState} from "recoil";
import {videoState} from "../store/common";
import ReactPlayer from "react-player";


const PopupVideo = () => {
    const [video, setVideo] = useRecoilState(videoState);
    const onVideo = () => {
        setVideo(!video)
    }
    return (
        <>
            <Popup.Dimmed></Popup.Dimmed>
            <Popup.Wrap height={'auto'}>
                <Popup.Head>
                    <Popup.TitleRow>

                    </Popup.TitleRow>
                    <Popup.Close type={'button'} onClick={onVideo}><span className={'hidden'}>닫기</span></Popup.Close>
                </Popup.Head>
                <Popup.Content height={'auto'}>
                    <div className={'inner'} >
                        <ReactPlayer width={'100%'} height={'auto'} url={'/videos/smart_video.mp4'} controls={true} playing={true}/>
                    </div>
                </Popup.Content>
            </Popup.Wrap>
        </>
    );
};

export default PopupVideo;