import {request} from "../index";
import {VIEW_CONTACT, VIEW_DOWNLOAD, VIEW_NEWS, VIEW_PROMOTION} from "./globals";

export const getNewsMedia = async ({pageNum, size}) => {
    const {data} = await request.get(VIEW_NEWS,{
        params:{
            pageNum:pageNum -1,
            size:size
        }
    });
    return data;
}


export const getPromotion = async () => {
    const {data} = await request.get(VIEW_PROMOTION);
    return data;
}


export const getFileDown = async (fileId) => {
    const data = await request.get(VIEW_DOWNLOAD + '/' + fileId,{
        responseType:'blob'
    });
    return data;
}

export const postContact = async ({contact,contactType,contents,department,email,name,position,serviceType}) => {
    let form = new FormData();
    form.set('contact',contact)
    form.set('contactType',contactType)
    form.set('contents',contents)
    form.set('department',department)
    form.set('email',email)
    form.set('name',name)
    form.set('position',position)
    form.set('serviceType',serviceType)

    const data = await request.post(VIEW_CONTACT,form);
    return data;
}