import styled from "styled-components";

export const ServiceArea = {
    Wrap: styled.div`
      padding:130px 0 0;text-align:center;
      @media screen and (max-width:1600px){
        padding:130px 100px 0;
      }
      @media screen and (max-width:1280px){
        padding:110px 40px 0;
      }
      @media screen and (max-width:1024px){
        padding:90px 40px 0;
      }
      @media screen and (max-width:768px){
        padding:70px 30px 0;
      }
    `,
    Title: styled.h1`
      font-size:44px;color:#000;font-weight:600;display:block;margin-bottom:39px;
      @media screen and (max-width:1280px){
        font-size:36px;margin-bottom:36px;
      }
      @media screen and (max-width:1024px){
        font-size:32px;margin-bottom:32px;
      }
      @media screen and (max-width:768px){
        font-size:28px;margin-bottom:28px;
      }
    `,
    Desc:styled.p`


      color:#000;font-size:24px;font-weight:600;margin-bottom:70px;word-break:keep-all;
      span{
        display:block;font-weight:400;line-height:38px;margin-top:44px;
      }
      @media screen and (max-width:1280px){
        font-size:22px;margin-bottom:60px;
        span{line-height:34px;margin-top:34px;}
      }
      @media screen and (max-width:1080px){
        font-size:18px;margin-bottom:40px;
        span{line-height:30px;margin-top:26px;}
      }
      @media screen and (max-width:768px){
        font-size:16px;margin-bottom:50px;
        span{line-height:26px;margin-top:20px;}
      }
      //
      //
      //color:#000;font-size:24px;line-height: 38px;font-weight:400;margin-bottom:70px;word-break:keep-all;
      //@media screen and (max-width:1280px){
      //  font-size:22px;line-height: 34px;
      //}
      //@media screen and (max-width:1024px){
      //  font-size:20px;line-height: 30px;margin-bottom:65px;
      //}
      //@media screen and (max-width:768px){
      //  font-size:18px;line-height:26px;margin-bottom:60px;
      //}
    `,
    Button:styled.button`
      width:290px;height: 80px;border-radius:40px;border:1px solid #3100ff;font-size:16px;font-weight:600;color:#3100ff;
      @media screen and (max-width:768px){
        width:208px;height:56px;font-size:14px;
      }
    `,
}

export const ServiceVisual = {
    Wrap:styled.div`
      position:relative;background: url(${p => p.bg && p.bg})no-repeat center;background-size:cover;margin-top:120px;
      &:before{position:absolute;left:0;top:0;z-index:1;width:100%;height:100%;content:'';background:rgba(0,0,0,.6);}
      @media screen and (max-width:1280px){
        margin-top:100px;padding:0 40px
      }
      @media screen and (max-width:1080px){
        margin-top:80px;
      }
      @media screen and (max-width:768px){
        margin-top:70px;padding:0 30px
      }
    `,
    Title:styled.h2`
      position:relative;z-index:2;display:block;color:#fff;font-size:32px;font-weight:600;margin-bottom:45px;text-align:center;padding-top:168px;
      @media screen and (max-width:1280px){
        padding-top:100px;font-size:28px;
      }
      @media screen and (max-width:1080px){
        padding-top:80px;font-size:24px;
      }
      @media screen and (max-width:768px){
        padding-top:70px;font-size:20px;
      }
    `,
    Desc:styled.p`
      position:relative;z-index:2;padding-bottom:163px;word-break:keep-all;
      span{display:block;line-height: 36px;color:#fff;font-size:24px;font-weight:400;text-align:center;
        &:not(:last-child){margin-bottom:20px;}
      }
      @media screen and (max-width:1280px){
        padding-bottom:100px;
        span{font-size:22px;line-height:34px;}
      }
      @media screen and (max-width:1080px){
        padding-bottom:80px;
        span{font-size:18px;line-height:30px;}
      }
      @media screen and (max-width:768px){
        padding-bottom:70px;
        span{font-size:16px;line-height:26px;}
      }
    `
}
export const SwiperArea = {
    Contain:styled.div`
      width:100%;text-align: center;
      
    `,
    Wrap: styled.div`
      max-width:1530px;margin:0 auto;padding-top:180px; padding-bottom:300px;
      @media screen and (max-width:1600px){
        max-width:none;padding:130px 100px 150px;
      }
      @media screen and (max-width:1280px){
        padding:110px 40px 130px;
      }
      @media screen and (max-width:1024px){
        padding:90px 40px 90px;
      }
      @media screen and (max-width:768px){
        padding:70px 30px 90px;
      }
      .swiper{overflow:visible;
        .swiper-slide{
          opacity:.5;
          &.swiper-slide-active, &.swiper-slide-next{opacity:1}
          img{width:100%;}
        }
        .swiper-button-prev, .swiper-button-next{
          top:calc(100% + 60px);width:110px;height:48px;
          &:after{display:none;}
        }
        .swiper-button-prev{background:url(${require('../assets/images/solution/swiper-prev.png')})no-repeat center;}
        .swiper-button-next{background:url(${require('../assets/images/solution/swiper-next.png')})no-repeat center;}
      }
      @media screen and (max-width:768px){
        .swiper{
          .swiper-button-prev{background-size:70px 5px;background-position:left center}
          .swiper-button-next{background-size:70px 5px;background-position: right center; }
          .swiper-button-prev, .swiper-button-next{
            top:calc(100% + 40px);
          }
        }
      }
    `,
    Title:styled.h1`
      color:#000;font-size:44px;font-weight:600;display:block;margin-bottom: 109px;
      @media screen and (max-width:1280px){
        font-size:38px;margin-bottom:70px;
      }
      @media screen and (max-width:1024px){
        font-size:34px;margin-bottom:50px;
      }
      @media screen and (max-width:768px){
        font-size:28px;margin-bottom:36px;
      }
    `

}


export const MetaverseArea = {
    Wrap: styled.div`
      padding:130px 0 120px;text-align:center;
      @media screen and (max-width:1600px){
        max-width:none;padding:130px 100px 120px;
      }
      @media screen and (max-width:1280px){
        padding:110px 40px 130px;
      }
      @media screen and (max-width:1024px){
        padding:90px 40px 90px;
      }
      @media screen and (max-width:768px){
        padding:70px 30px 90px;
      }
    `,
    Title: styled.h1`
      font-size:44px;color:#000;font-weight:600;display:block;margin-bottom:16px;word-break:keep-all;
      @media screen and (max-width:1280px){
        font-size:36px;
      }
      @media screen and (max-width:1024px){
        font-size:32px;margin-bottom:14px;
      }
      @media screen and (max-width:768px){
        font-size:28px;margin-bottom:12px;
      }
    `,
    Desc:styled.p`
      color:#000;font-size:24px;font-weight:600;margin-bottom:70px;word-break:keep-all;
      span{
        display:block;font-weight:400;line-height:38px;margin-top:44px;
      }
      @media screen and (max-width:1280px){
        font-size:22px;margin-bottom:60px;
        span{line-height:34px;margin-top:34px;}
      }
      @media screen and (max-width:1080px){
        font-size:18px;margin-bottom:40px;
        span{line-height:30px;margin-top:26px;}
      }
      @media screen and (max-width:768px){
        font-size:16px;margin-bottom:50px;
        span{line-height:26px;margin-top:20px;}
      }
    `,
    Button:styled.button`
      width:290px;height: 80px;border-radius:40px;border:1px solid #3100ff;font-size:16px;font-weight:600;color:#3100ff;
      @media screen and (max-width:768px){
        width:208px;height:56px;font-size:14px;
      }
    `,
}

export const NewsArea = {
    Wrap: styled.div`
      padding:130px 0 100px;text-align:center;
      @media screen and (max-width:1600px){
        padding:130px 100px 85px;
      }
      @media screen and (max-width:1280px){
        padding:110px 40px 80px;
      }
      @media screen and (max-width:1024px){
        padding:90px 40px 75px;
      }
      @media screen and (max-width:768px){
        padding:70px 30px 70px;
      }
      
    `,
    Title: styled.h1`
      font-size:44px;color:#000;font-weight:600;display:block;
      @media screen and (max-width:1280px){
        font-size:36px;
      }
      @media screen and (max-width:1024px){
        font-size:32px;
      }
      @media screen and (max-width:768px){
        font-size:28px;
      }
    `,
    NewsList:styled.div`
      max-width:1530px;margin:0 auto;padding-bottom:200px;
      @media screen and (max-width:1600px){
        max-width:none;padding:0 100px 200px;
      }
      @media screen and (max-width:1280px){
        padding:0 40px 160px;
      }
      @media screen and (max-width:1024px){
        padding:0 40px 120px;
      }
      @media screen and (max-width:768px){
        padding:0 30px 70px;
      }
    `,
    CardList: styled.div`
      max-width:1530px;margin:48px auto 0;display:flex;flex-wrap:wrap;padding-bottom:200px;
      @media screen and (max-width:1600px){
        max-width:none;padding:0 100px 200px;
      }
      @media screen and (max-width:1280px){
        padding:0 40px 160px;
      }
      @media screen and (max-width:1024px){
        padding:0 40px 120px;
      }
      @media screen and (max-width:768px){
        padding:0 30px 70px;
      }
    `,
    CardContain:styled.div`
      text-align:center;
    `,
    Tag:styled.span`
      display:inline-block;border-radius:50px;border:1px solid #3100ff;height:48px;line-height:48px;box-sizing:border-box;padding:0 26px;
      color:#3100ff;font-size:20px;font-weight:500;
    `
}

export const InquiryArea = {
    Wrap: styled.div`
      padding:130px 0 176px;text-align:center;
      @media screen and (max-width:1600px){
        padding: 130px 100px;
      }
      @media screen and (max-width:1280px){
        padding: 100px 40px;
      }
      @media screen and (max-width:768px){
        padding: 70px 30px;
      }
    `,
    Title: styled.h1`
      font-size:44px;color:#000;font-weight:600;display:block;margin-bottom:40px;
      @media screen and (max-width:1280px){
        font-size:38px
      }
      @media screen and (max-width:1024px){
        font-size:34px
      }
      @media screen and (max-width:768px){
        font-size:28px
      }
    `,
    Desc:styled.p`
      color:#000;font-size:24px;font-weight:400;margin-bottom:135px;word-break: keep-all;
      @media screen and (max-width:1280px){
        font-size:22px;margin-bottom:100px;
      }
      @media screen and (max-width:1024px){
        font-size:20px;line-height:28px;margin-bottom:85px;
      }
      @media screen and (max-width:768px){
        font-size:18px;line-height:24px;margin-bottom:65px;
      }
    `,
    Form:styled.form`
      max-width:708px;margin:0 auto;
      >div{
        &:not(:last-child){margin-bottom:40px;}
      }
      @media screen and (max-width: 1024px){
        >div{
          &:not(:last-child){margin-bottom:30px;}
        }
      }
      
    `,
    Button:styled.button`
      width:100%;height: 72px;background: #3100ff;border-radius: 8px;color:#fff;font-size:20px;font-weight: 600;margin-top:48px;transition:.4s;
      &:hover{background:#1e5dfc;}
      @media screen and (max-width:1024px){
        margin-top:0;height:60px;
      }
      @media screen and (max-width:1024px){
        height:40px;
      }
      
    `
}