import React from 'react';
import {LabelArea} from "./style";

const LabelTextarea = ({id, label, require, name, placeholder, onChange, value}) => {
    return (
        <LabelArea.Wrap align={'flex-start'}>
            <LabelArea.Label htmlFor={id} className={require ? 'req' : undefined}>{label}</LabelArea.Label>
            <LabelArea.Textarea id={id} name={name} placeholder={placeholder} onChange={onChange} value={value}></LabelArea.Textarea>
        </LabelArea.Wrap>
    );
};

export default LabelTextarea;