import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {ServiceArea, ServiceVisual, SwiperArea} from "../style";
import ImgVisual from "../../assets/images/img-visual-cmls.png";
import {useTranslation} from "react-i18next";
import {blankLinkNotion} from "../../utils/script";


const Cmls = () => {
    const {t} = useTranslation('translation');
    return (
        <>

            <ServiceArea.Wrap>
                <ServiceArea.Title>{t('solution.0.name')}</ServiceArea.Title>
                <ServiceArea.Desc>{t('solution.0.desc')}
                    <span dangerouslySetInnerHTML={{__html:t('solution.0.subDesc')}}></span>
                </ServiceArea.Desc>
                <ServiceArea.Button type={'button'} onClick={blankLinkNotion}>{t('common.notionBtn')}</ServiceArea.Button>
            </ServiceArea.Wrap>
            <ServiceVisual.Wrap bg={ImgVisual}>
                <ServiceVisual.Title>
                    {t('common.mainFunc')}
                </ServiceVisual.Title>
                <ServiceVisual.Desc>
                    <span>- {t('solution.0.skill.0')}</span>
                    <span>- {t('solution.0.skill.1')}</span>
                    <span>- {t('solution.0.skill.2')}</span>
                    <span>- {t('solution.0.skill.3')}</span>
                </ServiceVisual.Desc>
            </ServiceVisual.Wrap>
            <SwiperArea.Contain>

                <SwiperArea.Wrap>
                    <SwiperArea.Title>
                        Detailed our solution
                    </SwiperArea.Title>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={68}
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                            1025:{
                                slidesPerView:2
                            }
                        }}
                    >
                        <SwiperSlide><img src={require('../../assets/images/visual/item-cmls-01.png')}/></SwiperSlide>
                        <SwiperSlide><img src={require('../../assets/images/visual/item-cmls-02.png')}/></SwiperSlide>
                        <SwiperSlide><img src={require('../../assets/images/visual/item-cmls-03.png')}/></SwiperSlide>
                    </Swiper>
                </SwiperArea.Wrap>
            </SwiperArea.Contain>

        </>
    );
};

export default Cmls;