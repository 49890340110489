import React, {useState} from 'react';
import styled from "styled-components";
import Cert from "../../components/Cert";
import {INTRO_TEXT} from "../../utils/common";
import PopupCert from "../../popup/PopupCert";
import {useTranslation} from "react-i18next";
import cert01 from "../../assets/images/intro/img-cert-01.png";
import cert02 from "../../assets/images/intro/img-cert-02.png";
import cert03 from "../../assets/images/intro/img-cert-03.png";
import cert04 from "../../assets/images/intro/img-cert-04.png";
import cert05 from "../../assets/images/intro/img-cert-05.png";
import cert08 from "../../assets/images/intro/img-cert-08.png";

const CertificationArea = {
    Wrap:styled.div`
      max-width:1530px;margin:0 auto;padding:130px 0 140px;
      @media screen and (max-width:1600px){
        max-width:none;padding: 130px 100px;
      }
      @media screen and (max-width:1280px){
        padding: 100px 40px;
      }
      @media screen and (max-width:768px){
        padding: 70px 30px;
      }
    `,
    Title:styled.h1`
      font-weight:600;font-size:44px;color:#000;text-align:center;
      @media screen and (max-width:1280px){
        font-size:38px
      }
      @media screen and (max-width:1024px){
        font-size:34px
      }
      @media screen and (max-width:768px){
        font-size:28px
      }
    `,
    List:styled.div`
      max-width:1530px;display:flex;flex-wrap:wrap;margin:0 auto;padding-bottom:190px;
      @media screen and (max-width:1600px){
        max-width: none;padding:0 100px 160px;
      }
      @media screen and (max-width:1280px){
        padding:0 40px 100px;
      }
      @media screen and (max-width:768px){
        padding:0 30px 70px;
      }
    `
}

const Performance = () => {
    const {t} = useTranslation('translation');
    const list = [
        {tag:t('about.performance.category.0'),desc:t('about.performance.content.0.title'), src:cert01, name:t('about.performance.content.0.title'), date:'2022-11-16', type:'성적서(Reports)', agency:'-'},
        {tag:t('about.performance.category.0'),desc:t('about.performance.content.1.title'), src:cert02, name:t('about.performance.content.1.title'), date:'2022-11-16', type:'성적서(Reports)', agency:'-'},
        {tag:t('about.performance.category.0'),desc:t('about.performance.content.2.title'), src:cert03, name:t('about.performance.content.2.title'), date:'2022-11-16', type:'성적서(Reports)', agency:'-'},
        {tag:t('about.performance.category.1'),desc:t('about.performance.content.3.title'), src:cert04, name:t('about.performance.content.3.title'), date:'2022-11-16', type:'성적서(Reports)', agency:'-'},
        {tag:t('about.performance.category.1'),desc:t('about.performance.content.4.title'), src:cert05, name:t('about.performance.content.4.title'), date:'2022-11-16', type:'성적서(Reports)', agency:'-'},
        {tag:t('about.performance.category.1'),desc:t('about.performance.content.5.title'), src:null, name:t('about.performance.content.5.title'), date:'2022-11-16', type:'성적서(Reports)', agency:'-'},
        {tag:t('about.performance.category.1'),desc:t('about.performance.content.6.title'), src:null, name:t('about.performance.content.6.title'), date:'2022-11-16', type:'성적서(Reports)', agency:'-'},
        {tag:t('about.performance.category.1'),desc:t('about.performance.content.7.title'), src:cert08, name:t('about.performance.content.7.title'), date:'2022-11-16', type:'성적서(Reports)', agency:'-'},
        {tag:t('about.performance.category.2'),desc:t('about.performance.content.8.title'), src:null, name:t('about.performance.content.8.title'), date:'2022-11-16', type:'성적서(Reports)', agency:'-'},
        {tag:t('about.performance.category.2'),desc:t('about.performance.content.9.title'), src:null, name:t('about.performance.content.9.title'), date:'2022-11-16', type:'성적서(Reports)', agency:'-'},
        {tag:t('about.performance.category.2'),desc:t('about.performance.content.10.title'), src:null, name:t('about.performance.content.10.title'), date:'2022-11-16', type:'성적서(Reports)', agency:'-'},
        {tag:t('about.performance.category.2'),desc:t('about.performance.content.11.title'), src:null, name:t('about.performance.content.11.title'), date:'2022-11-16', type:'성적서(Reports)', agency:'-'},
    ]
    const [popOpen, setPopOpen] = useState(false)
    const [popData, setPopData] = useState({
        src:'',
        tag:'',
        desc:'',
        name:'',
        date:'',
        agency:''
    });
    const onClick = (src, tag, desc, name, date, agency) => {
        setPopOpen(!popOpen)
        setPopData({
            src:src,
            tag:tag,
            desc:desc,
            name:name,
            date:date,
            agency: agency
        })
    }
    const onClose = () => {
        setPopOpen(!popOpen)
    }
    return (
        <>
            <CertificationArea.Wrap>
                <CertificationArea.Title>{t('about.performance.title')}</CertificationArea.Title>

            </CertificationArea.Wrap>
            <CertificationArea.List>
                {list.map(({src, tag, desc, name, date, agency},index) => {
                    return <Cert key={index} src={src} tag={tag} desc={desc} onClick={() => onClick(src, tag, desc, name, date, agency)}/>
                })}

            </CertificationArea.List>
            {popOpen && <PopupCert src={popData.src} tag={popData.tag} desc={popData.desc} name={popData.name} date={popData.date} agency={popData.agency} onClose={onClose}/>}
        </>
    );
};

export default Performance;