import React from 'react';
import styled from "styled-components";
import {INTRO_TEXT} from "../../utils/common";
import {useTranslation} from "react-i18next";


const Partner = {
    Wrap: styled.div`
      max-width:1530px;margin:0 auto;padding:130px 0 170px;
      @media screen and (max-width:1600px){
        padding:130px 100px
      }
      @media screen and (max-width:1280px){
        padding:100px 40px
      }
      @media screen and (max-width:768px){
        padding:70px 30px
      }
    `,
    Video:styled.div`
      width:100%;height: 864px;border-radius:12px;overflow:hidden;background:red;margin-bottom:200px;
      @media screen and (max-width:1600px){
        height: 760px;margin-bottom:160px;
      }
      @media screen and (max-width:1289px){
        height: 640px;margin-bottom:120px
      }
      @media screen and (max-width:1024px){
        height: 520px;margin-bottom:100px
      }
      @media screen and (max-width:768px){
        height: 400px;margin-bottom:80px
      }
      @media screen and (max-width:375px){
        height: 220px;margin-bottom:60px
      }
    `,
    Title:styled.strong`
      font-size:44px;color:#000;font-weight:600;display:block;margin-bottom: 30px;text-align: center;
      @media screen and (max-width:1280px){
        font-size:38px
      }
      @media screen and (max-width:1024px){
        font-size:34px
      }
      @media screen and (max-width:768px){
        font-size:28px
      }
    `,
    Desc:styled.p`
      font-size:24px;font-weight:400;display:block;color:#000;text-align: center;margin-bottom:90px;

      @media screen and (max-width:1280px){
        line-height: 35px;font-size:22px;margin-bottom:70px;
      }
      @media screen and (max-width:1024px){
        line-height: 30px;font-size:19px;margin-bottom:50px;
      }
      @media screen and (max-width:768px){
        line-height: 26px;font-size:16px;margin-bottom:30px;
      }
    `,
    List:styled.ul`
      display:flex;flex-wrap:wrap;
    `,
    Item:styled.li`
      height:260px;display:flex;align-items:center;justify-content:center;width:calc(25% - 22.5px);margin-right:30px;margin-top:30px;
      background:#fbfbfb;border-radius:10px;
      img{width:100%;}
      &:nth-child(4n){margin-right:0}
      &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4){margin-top:0}
      @media screen and (max-width:1024px){
        width:calc(33.3% - 20px);height:220px;
        &:nth-child(4n){margin-right:30px}
        &:nth-child(4){margin-top:30px}
        &:nth-child(3n){margin-right:0;}
      }
      @media screen and (max-width:768px){
        width:calc(50% - 10px);height:180px;margin-right:20px;margin-top:20px;
        &:nth-child(3n){margin-right:20px;}
        &:nth-child(2n){margin-right:0}
        &:nth-child(1), &:nth-child(2){margin-top:0;}
        &:nth-child(3){margin-top:20px;}
        &:nth-child(4){margin-top:20px;}
      }
      @media screen and (max-width:768px){
        height: 120px;
      }
    `
}

const Partners = () => {
    const {t} = useTranslation('translation')
    return (
        <>
            <Partner.Wrap>

                <Partner.Title>{t('about.partners.title')}</Partner.Title>
                <Partner.Desc>{t('about.partners.desc')}</Partner.Desc>
                <Partner.List>
                    {
                        INTRO_TEXT.PARTNER.map(({name, src},index) => {
                            return (
                                <Partner.Item key={index}>
                                    <img src={src} alt={name}/>
                                </Partner.Item>
                            )
                        })
                    }
                </Partner.List>
            </Partner.Wrap>
        </>
    );
};

export default Partners;