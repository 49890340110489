import React, {useEffect} from 'react';
import styled from "styled-components";
import {MAIN_TEXT} from "../../utils/common";
import {Btn} from "../Button";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import {useRecoilState} from "recoil";
import {videoState} from "../../store/common";
import PopupVideo from "../../popup/PopupVideo";

const Page = {
    Wrap: styled.div`
      max-width:1530px;height:100%;display:flex;flex-direction:column;justify-content:space-between;padding:208px 0 160px;margin:0 auto;
      @media screen and (max-width: 1600px){
        max-width:none;padding: 200px 100px 100px;
      }
      @media screen and (max-width: 768px){
        justify-content:center;padding: 0 30px;width:400px;
      }
      @media screen and (max-width: 400px){
        width:auto;
      }
    `,
}

const TextContainer = {
    TextGroup:styled.div`
      display:flex;flex-direction:column;justify-content:center;
    `,
    Text01: styled.p`
      color:#fff;font-size:20px;line-height:30px;font-weight:400;
      br{display:block;}
      @media screen and (max-width: 768px){
        font-size:14px;line-height:20px;margin-bottom:50px;margin-top:40px;
      }
      
    `,
    Text02: styled.strong`
      color:#fff;font-size:48px;line-height: 74px;margin-bottom:38px;font-weight:600;
      br{display:block;}
      @media screen and (max-width: 768px){
        font-size:24px;line-height:34px;letter-spacing: 0;margin-bottom:24px;width:90%;
        br{display:none;}
      }
      @media screen and (max-width:470px){
        
      }
    `,
    Text03: styled.p`
      color:#fff;font-size:24px;line-height:38px;font-weight:400;word-break:keep-all;
      br{display:block;}
      @media screen and (max-width: 768px){
        font-size:16px;line-height:24px;letter-spacing: -1px;margin-bottom:100px;
      }
      @media screen and (max-width:470px){
        width:250px;
        br{display:none}
      }
    `,
    Button: styled(Btn.Wrap)`
      width:290px;height:80px;
      @media screen and (max-width:768px){
        width:208px;height:56px;
      }
    `,
    ButtonText:styled(Btn.Text)`
      display:flex;align-items:center;font-size:16px;color:#1b1b1b;font-weight: 600;
      &:before{content: '';border:6px solid transparent; 
        border-right:6px solid #1b1b1b; border-bottom: 6px solid #1b1b1b;transform:rotate(-45deg) skew(15deg, 15deg);margin-right:15px;}
      @media screen and (max-width:768px){
        font-size:14px;
      }
    `
}

const Section01 = () => {
    const [video, setVideo] = useRecoilState(videoState);
    const onVideo = () => {
        setVideo(!video)
    }
    const {list} = MAIN_TEXT();
    return (
        <Page.Wrap>
            <TextContainer.Text01 dangerouslySetInnerHTML={{__html:list.SECTION_01.text01}}></TextContainer.Text01>
            <TextContainer.TextGroup>
                <TextContainer.Text02 dangerouslySetInnerHTML={{__html:list.SECTION_01.text02}}></TextContainer.Text02>
                <TextContainer.Text03 dangerouslySetInnerHTML={{__html:list.SECTION_01.text03}}></TextContainer.Text03>
            </TextContainer.TextGroup>
            <TextContainer.Button type={'button'} color={'white'} onClick={onVideo}>
                <TextContainer.ButtonText color={'white'}>{list.SECTION_01.button}</TextContainer.ButtonText>
            </TextContainer.Button>
        </Page.Wrap>
    );
};

export default Section01;