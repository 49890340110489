import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import OlMap from "../../components/OlMap";
import {INTRO_TEXT} from "../../utils/common";
import Tab2 from "../../components/Tab2";
import {useTranslation} from "react-i18next";

const LocationArea = {
    Wrap:styled.div`
      max-width:1530px;margin:0 auto;padding:130px 0 100px;text-align: center;
      @media screen and (max-width:1600px){
        padding: 130px 100px;
      }
      @media screen and (max-width:1280px){
        padding: 100px 40px;
      }
      @media screen and (max-width:768px){
        padding: 70px 30px 0;
      }
    `,
    Title:styled.h1`
      font-weight:600;font-size:44px;color:#000;margin-bottom:60px;text-align:center;
      @media screen and (max-width:1280px){
        font-size:38px;margin-bottom:50px;
      }
      @media screen and (max-width:1024px){
        font-size:34px;margin-bottom:40px;
      }
      @media screen and (max-width:768px){
        font-size:28px;margin-bottom:30px;
      }
    `,
    Info:styled.ul`
      display: inline-block;margin-bottom:136px;
      li{
        display:flex;align-items:center;font-size:24px;color:#000;font-weight:400;
        span{font-weight:500;width:160px;text-align:left};
        &:not(:last-child){margin-bottom:20px;}
      }
      @media screen and (max-width:1280px){
        margin-bottom:110px;
        li{
          font-size:20px;
          span{font-weight:500;width:160px;text-align:left};
          &:not(:last-child){margin-bottom:18px;}
        }
      }
      @media screen and (max-width:1024px){
        margin-bottom:80px;
        li{
          font-size:18px;
          span{width:140px;
          &:not(:last-child){margin-bottom:16px;}
        }
      }
      @media screen and (max-width:768px){
        margin-bottom:60px;
        li{
          font-size:16px;
          span{width:120px};
          &:not(:last-child){margin-bottom:14px;}
        }
      }
    `,
}
const Location = () => {
    const {t, i18n} = useTranslation('translation');

    const [tabList, setTabList] = useState([
        {name:t('about.location.office.0.name'), address:t('about.location.office.0.address'),lat:36.3524919928, lng:127.381328023, active:true},
        {name:t('about.location.office.1.name'), address:t('about.location.office.1.address'),lat:36.3515231, lng:127.371901, active: false},
        {name:t('about.location.office.2.name'), address:t('about.location.office.2.address'),lat:37.487805, lng:127.0134382,active: false},
        {name:t('about.location.office.3.name'), address:t('about.location.office.3.address'),lat:35.1043525, lng:128.9214147,active: false},
    ]);

    let newList = [...tabList];
    useEffect(() => {
        newList[0].name = t('about.location.office.0.name');
        newList[1].name = t('about.location.office.1.name');
        newList[2].name = t('about.location.office.2.name');
        newList[3].name = t('about.location.office.3.name');
        newList[0].address = t('about.location.office.0.address');
        newList[1].address = t('about.location.office.1.address');
        newList[2].address = t('about.location.office.2.address');
        newList[3].address = t('about.location.office.3.address');
        setTabList(newList)
    },[i18n.language]);

    const changeName = () => {
        if(window.innerWidth <= 1024){
            newList[0].name = t('about.location.office.0.name').split(' ')[0];
            newList[1].name = t('about.location.office.1.name').split(' ')[0];
            newList[2].name = t('about.location.office.2.name').split(' ')[0];
            newList[3].name = t('about.location.office.3.name').split(' ')[0];

        }else{
            newList[0].name = t('about.location.office.0.name')
            newList[1].name = t('about.location.office.1.name')
            newList[2].name = t('about.location.office.2.name')
            newList[3].name = t('about.location.office.3.name')

        }
        setTabList(newList)
    }
    useEffect(() => {
        window.addEventListener('resize', changeName)

        return () => {
            window.removeEventListener('resize', changeName)
        }
    })
    const onClick = (str) => {
        for(let i = 0; i < newList.length; i++){
            newList[i].active = false;
        }
        newList.filter(({name}) => name === str)[0].active = true;
        setTabList(newList)

    }
    useEffect(() => {
        for(let i = 0; i < newList.length; i++){
            newList[i].active = false;
        }
        newList[0].active = true;
        setTabList(newList)
    },[])
    return (
        <LocationArea.Wrap>
            <LocationArea.Title>{t('about.location.title')}</LocationArea.Title>
            <LocationArea.Info>
                <li><span>Tel</span>070-7769-0529</li>
                <li><span>Fax</span>070-7966-6529</li>
                <li><span>E-mail</span>support@movements.kr</li>
            </LocationArea.Info>
            <div>
                <Tab2 list={tabList} onClick={onClick}/>
                {
                    tabList.filter(({active}) => active === true).map(({name, address, lat, lng},index) => {
                        return <OlMap tag={name} address={address} lat={lat} lng={lng} key={index}/>
                    })
                }
            </div>
        </LocationArea.Wrap>
    );
};

export default Location;