import React, {forwardRef} from 'react';
import styled from "styled-components";

const RadioContain = {
    Wrap:styled.div`
      position:relative;
      &:not(:last-child){margin-right:80px;}
      @media screen and (max-width:1280px){
        &:not(:last-child){margin-right:60px;}
      }
      @media screen and (max-width:768px){
        &:not(:last-child){margin-right:40px;}
      }
    `,
    Input:styled.input.attrs('radio')`
      opacity:0;position:absolute;left:0;top:0;font-size:0;width:0;height:0;
      &:checked{
        +label{
          &:before{background:#f1f1f5;border:7px solid #3100ff;}
        }
      }
      @media screen and (max-width:1280px){
        &:checked{
          +label{
            &:before{border:6px solid #3100ff;}
          }
        }
      }
      @media screen and (max-width:768px){
        &:checked{
          +label{
            &:before{border:5px solid #3100ff;}
          }
        }
      }
    `,
    Label:styled.label`
      display:flex;align-items:center;
      span{margin-left:12px;font-size:20px;font-weight:500;color:#767676;}
      &:before{content:'';width:28px;height:28px;border-radius:50%;background:#f1f1f5;box-sizing:border-box;transition:.4s;}
      @media screen and (max-width:1280px){
        span{font-size:18px;}
        &:before{width:24px;height:24px;}
      }
      @media screen and (max-width:768px){
        span{font-size:16px;}
        &:before{width:20px;height:20px;}
      }
    `
}

const Radio = forwardRef(({id, label, name, checked, onChange, value},ref) => {
    return (
        <RadioContain.Wrap>
            <RadioContain.Input type={'radio'} id={id} name={name} defaultChecked={checked} onChange={onChange} value={value} ref={ref}/>
            <RadioContain.Label htmlFor={id}>{label ? <span>{label}</span> : null}</RadioContain.Label>
        </RadioContain.Wrap>
    );
});

export default Radio;