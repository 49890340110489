import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";

const TabSelDefault = {
    Wrap: styled.div`
      display: none;position:relative;height:60px;border-bottom:1px solid #dedede;box-sizing:border-box;
      @media screen and (max-width:1024px){
        display:block;
      }
    `,
    Selected: styled.strong`
      display:flex;align-items:center;justify-content:space-between;font-size:16px;font-weight:600;color:#000;padding:0 30px;height:100%;cursor: pointer;
      &:after{content: '';width:8px;height:8px;border-bottom:2px solid #000;border-left:2px solid #000;transform: rotate(-45deg) translateY(-8px)}
    `,
    List: styled.ul`
      position:absolute;left:0;top:calc(100% + 1px);display:none;width:100%;border-bottom:1px solid #dedede;background:#fff;z-index:10;
      
    `,
    Item:styled.li`
      padding:0 30px;line-height: 54px;cursor: pointer;font-size:16px;color:#8d8d8d;font-weight:400;
    `
}

const TabSel = {
    ...TabSelDefault,
    Wrap:styled(TabSelDefault.Wrap)`
      &.active{
        ${TabSelDefault.List}{display:block;}
        ${TabSelDefault.Selected}{
          &:after{transform:rotate(135deg) translate(5px, 2px);}
        }
      }
    `,
}
const TabSelect = ({list}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selName, setSelName] = useState('');
    const [show, setShow] = useState(false);
    const onToggle = () => {
        setShow(!show);
    }
    const onLink = (tabName,link) => {
        navigate(link)
        setSelName(tabName)
        onToggle();
    }
    useEffect(() => {
        setSelName(list.filter(({link}) => link === location.pathname)[0].tabName)
    },[location.pathname,list])
    return (
        <TabSel.Wrap className={show ? 'active' : undefined}>
            <TabSel.Selected onClick={onToggle}>{selName}</TabSel.Selected>
            <TabSel.List>
                {
                    list.map(({link,tabName},index) => {
                        return <TabSel.Item key={index} onClick={() => onLink(tabName,link)}>{tabName}</TabSel.Item>
                    })
                }
            </TabSel.List>
        </TabSel.Wrap>
    );
};

export default TabSelect;