import React from 'react';
import styled from "styled-components";

const NewsCardArea = {
    Wrap:styled.div`
      display:flex;padding:55px 0;border-bottom:1px solid #dedede;
      @media screen and (max-width:1280px){
        padding:50px 0;
      }
      @media screen and (max-width:1024px){
        padding:45px 0;
      }
      @media screen and (max-width:768px){
        flex-direction:column;padding:40px 0 30px;
      }
    `,
    Image:styled.div`
      width:470px;height:330px;background:#313131;overflow:hidden;margin-right:58px;
      img{display:block;width:100%;height:100%;}
      @media screen and (max-width: 1280px){
        width:360px;height:270px;margin-right:46px;
      }
      @media screen and (max-width: 1024px){
        width:300px;height:220px;margin-right:36px;
      }
      @media screen and (max-width: 768px){
        width:100%;height:220px;margin-right:0;background:none;
        img{width:auto;max-width:100%;margin:0 auto}
      }
    `,
    TextArea:styled.div`
      width:calc(100% - 528px);text-align:left;
      @media screen and (max-width: 1280px){
        width:calc(100% - 406px);
      }
      @media screen and (max-width: 1024px){
        width:calc(100% - 336px);
      }
      @media screen and (max-width: 768px){
        width:100%
      }
    `,
    Title:styled.strong`
      margin-top:50px;display:block;margin-bottom:20px;font-size:32px;color:#1b1b1b;font-weight:600;
      line-height:34px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;
      @media screen and (max-width:1280px){
        margin-top:40px;font-size:28px;
      }
      @media screen and (max-width:1024px){
        margin-top:35px;font-size:24px;
      }
      @media screen and (max-width:768px){
        line-height:32px;margin-top:30px;font-size:20px;display: -webkit-box;word-break:keep-all;
        -webkit-line-clamp: 2;-webkit-box-orient: vertical;white-space: pre-wrap;
      }
    `,
    Desc:styled.p`
      font-size:24px;font-weight:400;color:#1b1b1b;margin-bottom:64px;line-height:38px;overflow: hidden;
      text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;
      @media screen and (max-width:1280px){
        margin-bottom:46px;font-size:22px;line-height:34px;
      }
      @media screen and (max-width:1024px){
        margin-bottom:34px;font-size:18px;line-height:30px;
      }
      @media screen and (max-width:768px){
        margin-bottom:26px;font-size:16px;line-height:26px;
      }
    `,
    Date:styled.span`
      display:block;margin-top:64px;font-size:20px;color:#767676;font-weight:400;
      @media screen and (max-width:1280px){
        margin-top:46px;font-size:18px;
      }
      @media screen and (max-width:1024px){
        margin-top:34px;font-size:16px;
      }
      @media screen and (max-width:768px){
        margin-top:28px;font-size:14px;
      }
    `
}

const NewsCard = ({src, title, desc, date, url}) => {
    return (
        <a href={url} target={"_blank"} title={title}>
            <NewsCardArea.Wrap>
                    <NewsCardArea.Image>
                        <img src={src} alt={title}/>
                    </NewsCardArea.Image>
                    <NewsCardArea.TextArea>
                        <NewsCardArea.Title>{title}</NewsCardArea.Title>
                        <NewsCardArea.Desc>{desc}</NewsCardArea.Desc>
                        <NewsCardArea.Date>{date}</NewsCardArea.Date>
                    </NewsCardArea.TextArea>
            </NewsCardArea.Wrap>
        </a>
    );
};

export default NewsCard;