import React from 'react';
import {Route, Routes} from "react-router-dom";
import Visual from "../components/Visual";
import {INQUIRY_TEXT, MENU_LIST, NEWS_TEXT} from "../utils/common";
import Tab from "../components/Tab";
import Online from "../pages/inquiry/Online";
import visual from "../assets/images/visual/visual-inquiry.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import TabSelect from "../components/TabSelect";
import {useTranslation} from "react-i18next";

const InquiryRoute = () => {
    const { t } = useTranslation('translation');
    const [menuList] = MENU_LIST();
    return (
        <div id={'wrap'}>
            <Header/>
            <Visual title={t('visual.4.name')} src={visual}/>
            <Tab list={menuList[4].dep2}/>
            <TabSelect list={menuList[4].dep2}/>
            <Routes>
                <Route path={'/online'} element={<Online/>}/>
            </Routes>
            <Footer/>
        </div>
    );
};

export default InquiryRoute;